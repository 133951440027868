<template>
  <div>
    <h5 style="font-size: medium; margin-top: 25px; color: #0d0d0d">
      Schedule and send Your BroadcastMessage
    </h5>
    <span style="font-size: small; margin-top: 5px; color: #aaa3a3; font-weight: bolder"
      >When do you want to send it?</span
    >

    <div class="radio-options">
    <div class="radio-row">
      <label class="radio-label">
        <input
          type="radio"
          id="sendNow"
          value="sendNow"
          v-model="selectedOption"
          class="radio-input"
        />
        Send now
      </label>
    </div>
    <div class="radio-row">
      <label class="radio-label">
        <input
          type="radio"
          id="scheduleTime"
          value="scheduleTime"
          v-model="selectedOption"
          class="radio-input"
          disabled
        />
        Schedule for a specific time . <span class="tag">PRO ✨</span> 
      </label>
    </div>
  </div>
  </div>
</template>
<script setup>
import { ref } from 'vue';

const selectedOption = ref('sendNow'); // Default selection is "Send now"

// Expose selected option for parent component to access
const emitSelectedOption = () => {
  return {
    selectedOption
  };
};


</script>
<style scoped>
.radio-options {
  display: flex;
  flex-direction: column;
}

.radio-row {
  margin-bottom: 10px;
}

.radio-label {
  display: flex;
  align-items: center;
}

.radio-input {
  margin-right: 10px;
}
.tag {
  /* position: absolute; */
  /* top: 20px;; */
  background-color: #144888;
  color: white;
  padding: 3px 8px;
  border-radius: 10px;
  font-size: 12px;
  font-weight: bold;
}
</style>