// import { ICustomerDataModel } from "@/Interfaces/DataModel/CustomerDataModel";
import ApiService from "../Common/ApiService";
import { CustomerProfileApi } from "../Common/ApiRoutes";
import { IResponseDataModel } from "@/Interfaces/DataModel/ResponseDataModel";


function getAllCustomers(sellerId: string, page: number): Promise<IResponseDataModel<any>> {
    const { url, method }: { url: string, method: string } = CustomerProfileApi.getAllCustomerProfile(sellerId);
    return ApiService.SendRequest(url, method, { query: { page } })
}

function searchCustomers(sellerId: string, filter?: any): Promise<IResponseDataModel<any>> {
    const { url, method }: { url: string, method: string } = CustomerProfileApi.getAllCustomerProfile(sellerId);
    return ApiService.SendRequest(url, method, { query: { filter } })
}
function getACustomer(sellerId: string, customerId: string): Promise<IResponseDataModel<any>> {
    const { url, method }: { url: string, method: string } = CustomerProfileApi.getCustomerProfile(sellerId, customerId);
    return ApiService.SendRequest(url, method)
}
function removeCustomer(sellerId: string, customerId: string): Promise<IResponseDataModel<any>> {
    const { url, method }: { url: string, method: string } = CustomerProfileApi.removeCustomerProfile(sellerId, customerId);
    return ApiService.SendRequest(url, method)
}
function createACustomer(sellerId: string, CustomerDataModel: any): Promise<IResponseDataModel<any>> {
    const { url, method }: { url: string, method: string } = CustomerProfileApi.CreateCustomerProfile(sellerId);
    return ApiService.SendRequest(url, method, { data: CustomerDataModel })
}
function updateACustomerProfile(sellerId: string, CustomerDataModel: any): Promise<IResponseDataModel<any>> {
    const { url, method }: { url: string, method: string } = CustomerProfileApi.updateCustomerProfile(sellerId, CustomerDataModel.customerId);
    return ApiService.SendRequest(url, method, { data: CustomerDataModel })
}

function MessageSendingApi(sellerId: string, messageDataModel: any): Promise<IResponseDataModel<any>> {
    const { url, method }: { url: string, method: string } = CustomerProfileApi.MessageSendingApi(sellerId);
    return ApiService.SendRequest(url, method, { data: messageDataModel })
}

function CustomerCount(sellerId: string): Promise<IResponseDataModel<any>> {
    const { url, method }: { url: string, method: string } = CustomerProfileApi.CustomerCount(sellerId);
    return ApiService.SendRequest(url, method)
}

export { getAllCustomers, getACustomer, removeCustomer, createACustomer, updateACustomerProfile, searchCustomers,MessageSendingApi,CustomerCount }