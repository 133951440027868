import { IResponseDataModel } from "@/Interfaces/DataModel/ResponseDataModel";
import { IValidationDataModel } from "@/Interfaces/DataModel/LoginDataModel"
import { ValidationApi } from "../Common/ApiRoutes";
import ApiService from "../Common/ApiService";

function CreateAValidationEntryRequest(sellerId: string, userId: string, data: IValidationDataModel): Promise<IResponseDataModel<IValidationDataModel>> {
    const { url, method }: { url: string, method: string } = ValidationApi.CreateValidationEntry(sellerId, userId)
    return ApiService.SendRequest(url, method, { data })
}

function CreateAOtpRequestForValidation(sellerId: string, userId: string, data: IValidationDataModel): Promise<IResponseDataModel<IValidationDataModel>> {
    const { url, method }: { url: string, method: string } = ValidationApi.CreateOtpRequstForValidation(sellerId, userId)
    
    return ApiService.SendRequest(url, method, { data })
}

function DeleteValidationEntry(sellerId: string, userId: string, validationKey: string): Promise<IResponseDataModel<IValidationDataModel>> {
    const { url, method }: { url: string, method: string } = ValidationApi.DeleteValidationEntry(sellerId, userId, validationKey)
    return ApiService.SendRequest(url, method)
}

export {
    CreateAOtpRequestForValidation,
    CreateAValidationEntryRequest,
    DeleteValidationEntry

}