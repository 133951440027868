function defaultState() {
  return {
    isCommoditySelected: false,
  }
}

export default {

  namespaced: true,

  state: defaultState(),

  mutations: {

    updateIsCommoditySelected(state: any, payload: boolean) {
      state.isCommoditySelected = payload
    },

    resetState(state: any) {
      Object.assign(state, defaultState())      

    },
  },

  actions: {
    setIsCommoditySelected(ctx: any, payload: boolean) {
      ctx.commit("updateIsCommoditySelected", payload)
    }

  },
  getters: {
    getIsCommoditySelected: (state: any) => state.isCommoditySelected,
  },
}
