// import bootstrap from "bootstrap/dist/js/bootstrap.min.js";

// TODO: update and remove
export interface TempIArgonModel {
  isPinned: boolean,
  darkMode: boolean,
  showSidenav: boolean,
  showNavbar: boolean,
  showFooter: boolean,
  layout: string,
  unReadMessageCount: number

}

function defaultState(): TempIArgonModel {
  return {
    isPinned: false,
    darkMode: false,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    layout: "default",
    unReadMessageCount: 0
  }
}

export default {
  namespaced: false,
  state: defaultState(),
  mutations: {

    navbarMinimize(state: TempIArgonModel) {
      const sidenav_show: any = document.querySelector("#app");

      if (sidenav_show.classList.contains("g-sidenav-show") && sidenav_show.classList.contains("g-sidenav-hidden")) {
        sidenav_show.classList.add("g-sidenav-pinned");
        sidenav_show.classList.remove("g-sidenav-hidden");
        state.isPinned = true;
      } else if (sidenav_show.classList.contains("g-sidenav-show") && sidenav_show.classList.contains("g-sidenav-pinned")) {
        sidenav_show.classList.add("g-sidenav-hidden");
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = false;
      } else if (sidenav_show.classList.contains("g-sidenav-show")) {
        sidenav_show.classList.add("g-sidenav-pinned");
        state.isPinned = true;
      }
    },

    toggleDefaultLayout(state: TempIArgonModel) {
      state.showNavbar = !state.showNavbar;
      state.showSidenav = !state.showSidenav;
      state.showFooter = !state.showFooter;
    },
    setDefaultLayout(state: TempIArgonModel) {
      state.showNavbar = true;
      state.showSidenav = true;
      state.showFooter = true;
    },
    removeDefaultLayout(state: TempIArgonModel) {
      state.showNavbar = false;
      state.showSidenav = false;
      state.showFooter = false;
    },
    updateDarkMode(state: TempIArgonModel, payload: boolean) {
      state.darkMode = payload
    },

    resetState(state: TempIArgonModel) {
      Object.assign(state, defaultState())      
    },
    changeUnreadMessageCount(state: TempIArgonModel, payload: number) {
      state.unReadMessageCount = payload
    }
  },
  actions: {
    updateUnreadMessageCount(ctx: any, payload: number) {
      ctx.commit("changeUnreadMessageCount", payload)
    }
  },
  getters: {
    getUnreadMessageCount: (state: TempIArgonModel) => state.unReadMessageCount,
    getLayout: (state: TempIArgonModel) => state.layout,
    getShowSidenav: (state: TempIArgonModel) => state.showSidenav,
    getShowNavbar: (state: TempIArgonModel) => state.showNavbar,
    getShowFooter: (state: TempIArgonModel) => state.showFooter
  },
}
