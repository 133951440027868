import swal from "sweetalert2";

async function confirmToDelete () {
    const result = await swal.fire({
        title: "Confirm to delete",
        showCancelButton: true,
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,
      });

      return result.isConfirmed
}

async function confirmToProceed (text = "") {
  const result = await swal.fire({
      title: "Confirm to proceed",
      text: text,
      showCancelButton: true,
      confirmButtonText: 'Ok',
      showLoaderOnConfirm: true,
    });

    return result.isConfirmed
}

export { confirmToDelete as confirmToDeleteWarning, confirmToProceed as confirmToProceedWarning }