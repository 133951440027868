import {
  ICommodityListViewModel,
  ICommodityTableActiveViewModel,
} from "@/Interfaces/DataModel/CommodityDataModel";
import { BulkImportTemplateUrl, commoditymandatoryfield } from "@/views/configs/commodityConfigs";
import {
  keyAndValueExistsInObject,
  stringIsValid,
  itemExistsInArray,
  checkArrayType,
} from "@/service/Common/utilityService";
import { CommodityMandatoryFields, commodityDataTypes } from "@/config/dataConfig";
import { csvDownloadFile } from "./apiService/CommodityService";

function IsCommodityNameValid(name: string): boolean {
  return stringIsValid(name);
}


function CategoryAndTagsIsValid(catagories: string[], tags: string[]): boolean {
  return catagories.length !== 0 && tags.length !== 0;
}

function CommodityCodeIsValid(code: string) {
  return stringIsValid(code);
}

function ValidateCommodityType(type: string) {
  return stringIsValid(type);
}

function IsAllFieldsHasValues(
  commodityActiveTableViewModel: ICommodityTableActiveViewModel[]
): boolean {
  return commodityActiveTableViewModel.every((tableRow) =>
    keyAndValueExistsInObject(tableRow)
  );
}

function IsImageAvailable(
  commodityActiveTableViewModel: ICommodityTableActiveViewModel[]
) {
  return commodityActiveTableViewModel.some(
    (tableRow) =>
      tableRow.type.toString().toLowerCase() === commodityDataTypes.file &&
      tableRow.value.toString().includes("http")
  );
}

function validateMandatoryFields(commodityActiveTableViewModel: ICommodityTableActiveViewModel[]) {
  const getName = (item: { name: string }) => (item.name || "").toLowerCase()
  return itemExistsInArray(commodityActiveTableViewModel.map(getName), CommodityMandatoryFields.map(getName))
}

function validateMandatoryFieldType(commodityActiveTableViewModel: ICommodityTableActiveViewModel[]) {
  return CommodityMandatoryFields.every(field => {
    const row = commodityActiveTableViewModel.find(item => (item.name || "").toString().toLowerCase() == field.name.toLowerCase())
    return row?.type == field.type
  })
}

function validateTableDataTypes(commodityActiveTableViewModel: ICommodityTableActiveViewModel[]) {
  return commodityActiveTableViewModel.every((row: any) => {
    if (row.type == commodityDataTypes.number) {
      return typeof row.value == "number" || !isNaN(row.value)
    } else {
      return typeof row.value == "string"
    }
  })
}

function CommodityValidation(
  commodityListViewModel: ICommodityListViewModel,
  commodityActiveTableViewModel: ICommodityTableActiveViewModel[]
): { isSuccess: boolean, errorMessage: string } {

  const isNameValid = IsCommodityNameValid(commodityListViewModel.commodityName);

  if (!isNameValid) {
    return { isSuccess: false, errorMessage: "Commodity name missing" }
  }

  const isCategoryAndTagValid = CategoryAndTagsIsValid(commodityListViewModel.catagories, commodityListViewModel.commodityTags);

  if (!isCategoryAndTagValid) {
    return { isSuccess: false, errorMessage: "Category or Tag missing" }
  }

  const isCommodityCodeValid = CommodityCodeIsValid(commodityListViewModel.commodityCode);

  if (!isCommodityCodeValid) {
    return { isSuccess: false, errorMessage: "Commodity code missing" }
  }

  const isCommodityTypeValid = ValidateCommodityType(commodityListViewModel.commodityType);

  if (!isCommodityTypeValid) {
    return { isSuccess: false, errorMessage: "Commodity type missing" }
  }

  const isAllFieldHasValues = IsAllFieldsHasValues(commodityActiveTableViewModel);

  if (!isAllFieldHasValues) {
    return { isSuccess: false, errorMessage: "Empty values found" }
  }

  const isAllMandatoryFieldExists = validateMandatoryFields(commodityActiveTableViewModel)

  if (!isAllMandatoryFieldExists) {
    return { isSuccess: false, errorMessage: "Mandatory items missing" }
  }

  const isAllMandatoryFieldTypeIsValid = validateMandatoryFieldType(commodityActiveTableViewModel)

  if (!isAllMandatoryFieldTypeIsValid) {
    return { isSuccess: false, errorMessage: "Invalid Field type found." }
  }

  const isAllDataTableFieldTypeIsValid = validateTableDataTypes(commodityActiveTableViewModel)

  if (!isAllDataTableFieldTypeIsValid) {
    return { isSuccess: false, errorMessage: "Invalid Field type found" }
  }

  const imageAvailable = IsImageAvailable(commodityActiveTableViewModel);

  if (!imageAvailable) {
    return { isSuccess: false, errorMessage: "Image not found" }
  }

  return { isSuccess: true, errorMessage: "" }

}

export async function csvDownload() {
  try {
      const blob = await csvDownloadFile(BulkImportTemplateUrl.url);
      const objUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', objUrl);
      link.setAttribute('download', BulkImportTemplateUrl.fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(objUrl);
  } catch (error) {
      console.error('Error downloading CSV:', error);
  }
}

export { CommodityValidation, IsCommodityNameValid, CategoryAndTagsIsValid, };
