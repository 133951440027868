import { ViewModelStatus } from "@/config/dataConfig"
import { createAMessageTemplate, getAllMessageTemplate, updateAMessageTemplate } from "./apiService/BroadcastTemplate"


export async function getAllTemplate(sellerId: string) {
    const data = await getAllMessageTemplate(sellerId)
    return data.result
}


export function   convertDataModelToViewModel(dataModel){

    // update aganist backend
    dataModel.saved= dataModel.templateId ? ViewModelStatus.Saved : ViewModelStatus.New
    return dataModel
}

export async function updateOrNewMessageTemplate(messageTemplateDataModel: any) {
    const data: any = messageTemplateDataModel.customerId ? await updateAMessageTemplate(messageTemplateDataModel.sellerId, messageTemplateDataModel) : 
    await createAMessageTemplate(messageTemplateDataModel.sellerId, messageTemplateDataModel);
    return data
}
