import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-4 row" }
const _hoisted_2 = { class: "col-sm-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AnalyticsCard = _resolveComponent("AnalyticsCard")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_AnalyticsCard, {
          title: "Location Statistics",
          headers: ['Product Name', 'Searched', 'Ordered'],
          pages: [
              {
                url: '/bits',
                views: 345,
                time: '00:17:07',
                rate: '40.91%',
              },
              {
                url: '/pages/argon-dashboard',
                views: 520,
                time: '00:23:13',
                rate: '30.14%',
              },
              {
                url: '/pages/soft-ui-dashboard',
                views: 122,
                time: '00:3:10',
                rate: '54.10%',
              },
              {
                url: '/bootstrap-themes',
                views: '1,900',
                time: '00:30:42',
                rate: '20.93%',
              },
              {
                url: '/react-themes',
                views: '1,442',
                time: '00:31:50',
                rate: '34.98%',
              },
              {
                url: '/product/argon-dashboard-angular',
                views: 201,
                time: '00:12:42',
                rate: '21.4%',
              },
              {
                url: '/product/material-dashboard-pro',
                views: '2,115',
                time: '00:50:11',
                rate: '34.98%',
              },
            ]
        }, null, 8, ["pages"])
      ])
    ])
  ]))
}