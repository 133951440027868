import { ICouponDataModel } from "@/Interfaces/DataModel/CouponDataModel";
import ApiService from "../Common/ApiService";
import { CouponBookletApi } from "../Common/ApiRoutes";
import { IResponseDataModel } from "@/Interfaces/DataModel/ResponseDataModel";


function CreateCouponBooklet(sellerId: string, data: ICouponDataModel): Promise<IResponseDataModel<ICouponDataModel>> {
    const { url, method }: { url: string, method: string } = CouponBookletApi.CreateACouponBooklet(sellerId);
    return ApiService.SendRequest(url, method, { data })
}

function GetAllCouponsBooklet(sellerId: string ): Promise<IResponseDataModel<ICouponDataModel[]>> {
    const { url, method }: { url: string, method: string } = CouponBookletApi.GetAllCouponBooklet(sellerId);
    return ApiService.SendRequest(url, method)
}

function GetCouponBooklet(sellerId: string, couponBookletId: string): Promise<IResponseDataModel<ICouponDataModel>> {
    const { url, method }: { url: string, method: string } = CouponBookletApi.GetACouponBooklet(sellerId, couponBookletId);
    return ApiService.SendRequest(url, method)
}

function UpdateCouponBooklet(sellerId: string, couponBookletId: string, data: ICouponDataModel,): Promise<IResponseDataModel<ICouponDataModel>> {
    const { url, method }: { url: string, method: string } = CouponBookletApi.UpdateACouponBooklet(sellerId, couponBookletId);
    return ApiService.SendRequest(url, method, { data })
}

function DeleteCouponBooklet(sellerId: string, couponBookletId: string): Promise<IResponseDataModel<ICouponDataModel>> {
    const { url, method }: { url: string, method: string } = CouponBookletApi.DeleteACouponBooklet(sellerId, couponBookletId);
    return ApiService.SendRequest(url, method)
}
function CouponBookletCount(sellerId: string): Promise<IResponseDataModel<any>> {
    const { url, method }: { url: string, method: string } = CouponBookletApi.CouponCount(sellerId);
    return ApiService.SendRequest(url, method)
}

export {
    CreateCouponBooklet,
    GetAllCouponsBooklet,
    GetCouponBooklet,
    UpdateCouponBooklet,
    DeleteCouponBooklet,
    CouponBookletCount
}