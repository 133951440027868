
import { IResponseDataModel } from "@/Interfaces/DataModel/ResponseDataModel";
import ApiService from "../Common/ApiService";
import { LicenseApi } from "../Common/ApiRoutes";
import { ILicenseDataModel } from "../../Interfaces/DataModel/LicenseDataModel";


function getLicenses(sellerId: string): Promise<IResponseDataModel<ILicenseDataModel[]>> {
   const { url, method }: { url: string, method: string } = LicenseApi.getLicense(sellerId)
    return ApiService.SendRequest(url, method)
}


function removeADeviceLicense(sellerId: string, deviceId: string): Promise<IResponseDataModel<ILicenseDataModel>> {
   const { url, method }: { url: string, method: string } = LicenseApi.removeDeviceLicense(sellerId, deviceId)
   return ApiService.SendRequest(url, method )
}

function disconnectADevice(sellerId: string, deviceId: string): Promise<IResponseDataModel<ILicenseDataModel>> {
   const { url, method }: { url: string, method: string } = LicenseApi.disconnectDevice(sellerId, deviceId)
   return ApiService.SendRequest(url, method )
}


export { getLicenses, removeADeviceLicense, disconnectADevice }
