
import { ICounterCardDataModel } from "@/Interfaces/DataModel/AnalyticsDataModel";
export const dateFilter = {
    Today:"Today",
    lastWeek: "Last 7 days",
    lastMonth: "Last 30 Days",
    lastYear: "This Year"
}
export const countGraph = {
    StoreCount: "Number of stores",
    CustomerCount: "Number of customers",
    CatalogueCount: "Number of Catalouge",
    CommodityCount :"Number of Commodity",
    CouponCount: "Number of Coupons",
    UserCount:"Number of Users"
}


export const emptyshareDataset = {
    xAxis: {
        type: "category",
        data: ["0", "0", "0", "0", "0", "0", "0"],
    },
    yAxis: {
        type: "value",
    },
    series: [
        {
            data: [0, 0, 0, 0, 0, 0, 0],
            type: "line",
            smooth: true,
        },
    ],
    title: {
        text: '',
        subtext: 'Count',
        left: 'left',
        top: 'top',
        textStyle: {
            fontSize: 10,
            fontWeight: 'bold',
            color: '#7c7d86'
        },
        subtextStyle: {
            fontSize: 14,
            color: '#000'
        }
    }
};



export const emptyareaPieces = {
    title: {
        text: '',
        subtext: 'Count',
        left: 'left',
        top: 'top',
        textStyle: {
            fontSize: 10,
            fontWeight: 'bold',
            color: '#7c7d86'
        },
        subtextStyle: {
            fontSize: 14,
            color: '#000'
        }
    },
    xAxis: {
        type: "category",
        boundaryGap: false,
        data: [
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
            "0",
        ],
    },
    yAxis: {
        type: "value",
        boundaryGap: [0, "30%"],
    },
    visualMap: {
        type: "piecewise",
        show: false,
        dimension: 0,
        seriesIndex: 0,
        pieces: [
            {
                gt: 0,
                lt: 8,
                color: "rgba(0, 0, 180, 0.4)",
            },
        ],
    },
    series: [
        {
            type: "line",
            smooth: 0.6,
            symbol: "none",
            lineStyle: {
                color: "#5470C6",
                width: 5,
            },
            markLine: {
                symbol: ["none", "none"],
                label: { show: false },
                data: [
                    { xAxis: "0" },
                    { xAxis: "0" },
                    { xAxis: "0" },
                    { xAxis: "0" },
                ],
            },
            areaStyle: {},
            data: [
                ["0", 0],
                ["0", 0],
                ["0", 0],
                ["0", 0],
                ["0", 0],
                ["0", 0],
                ["0", 0],
                ["0", 0],
                ["0", 0],
            ],
        },
    ],

}


export const emptytitleCardContent = [
    { title: "Total Coupons", content: "0", image: "LineChart1.png" ,proFeature:true},
    { title: "Published Coupons", content: "0", image: "LineChart2.png" ,proFeature:true},
    { title: "Low Stock Coupons", content: "0", image: "LineChart3.png" ,proFeature:true},
    { title: "Deadline Coupons", content: "0", image: "BarChart6.png" ,proFeature:true},
    { title: "Unpublished Coupons", content: "0", image: "BarChart5.png",proFeature:true },
]


export const emptyrowCardContent = [
    { title: "Total Revenue", content: "0", image: "LineChart1.png" },
    { title: "Published Products", content: "0", image: "LineChart2.png" },

]





export const emptyRevenueContent = {

    title: {
        text: 'Multiline Area Chart'
    },
    tooltip: {
        trigger: 'axis'
    },
    legend: {
        data: ['Series 1', 'Series 2']
    },
    xAxis: {
        type: 'category',
        boundaryGap: false,
        data: ['0', '0', '0', '0', '0', '0', '0']
    },
    yAxis: {
        type: 'value'
    },
    series: [
        {
            name: 'Series 1',
            type: 'line',
            areaStyle: {},
            data: [0, 0, 0, 0, 0, 0, 0]
        },
        {
            name: 'Series 2',
            type: 'line',
            areaStyle: {},
            data: [0, 0, 0, 0, 0, 0, 0]
        }
    ]


}



export const emptyCountCardContent:ICounterCardDataModel[] = [
    { title: countGraph.StoreCount,description:"", data: 0,url:"",method:"",proFeature:false},
    { title: countGraph.CustomerCount, description:"", data: 0,url:"",method:"",proFeature:false },
    { title: countGraph.CatalogueCount, description:"", data: 0,url:"",method:"",proFeature:false},
    { title: countGraph.CommodityCount, description:"", data: 0,url:"",method:"" ,proFeature:false},
    { title: countGraph.CouponCount, description:"", data: 0,url:"",method:"" ,proFeature:false},
    { title: countGraph.UserCount, description:"", data: 0,url:"",method:"" ,proFeature:false},
]
export enum DashboardTemplateUrl{
    url = "https://raw.githubusercontent.com/AICorpGlobal/public-buyconn-release-artifiacts/master/buyconn-templates/amk/dashboards/Overview.json",
    fileName = 'DashboardTemplate.v1.csv'
}


export const gaugeChartConfig={
    series : [
        {
            type : 'gauge' ,
            startAngle : 180 ,
            endAngle : 0 ,
            min : 0 ,
            max : 240 ,
            splitNumber : 12 ,
            itemStyle : {
                color : '#58D9F9' ,
                shadowColor : 'rgba(0,138,255,0.45)' ,
                shadowBlur : 10 ,
                shadowOffsetX : 2 ,
                shadowOffsetY : 2
            } ,
            progress : {
                show : true ,
                roundCap : true ,
                width : 20
            } ,
            pointer : {
                icon : 'path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z' ,
                length : '75%' ,
                width : 16 ,
                offsetCenter : [ 0 , '5%' ]
            } ,
            axisLine : {
                roundCap : false ,
                lineStyle : {
                    width : 18
                }
            } ,
            axisTick : {
                show: false,
                splitNumber : 2 ,
                lineStyle : {
                    width : 2 ,
                    color : '#000'
                }
            } ,
            splitLine : {
                show: false,
                length : 12 ,
                lineStyle : {
                    width : 3 ,
                    color : '#999'
                }
            } ,
            axisLabel : {
                show: false,
                distance : 30 ,
                color : '#999' ,
                fontSize : 20
            } ,
            title : {
                show : false
            } ,
            detail : {
                backgroundColor : '#fff' ,
                borderColor : '#999' ,
                borderWidth : 2 ,
                width : '60%' ,
                lineHeight : 25 ,
                height : 30 ,
                borderRadius : 6 ,
                offsetCenter : [ 0 , '35%' ] ,
                valueAnimation : true ,
                formatter : function ( value ) {
                    return '{value|' + value.toFixed ( 0 ) + '}';
                } ,
                rich : {
                    value : {
                        fontSize : 30 ,
                        fontWeight : 'bolder' ,
                        color : '#777'
                    } ,
                    unit : {
                        fontSize : 20 ,
                        color : '#999' ,
                        padding : [ 0 , 0 , - 20 , 10 ]
                    }
                }
            } ,
            data : [
                {
                    value : 0
                }
            ]
        }
    ]
}
export const CatloguegaugeChartConfig={
    series : [
        {
            type : 'gauge' ,
            startAngle : 180 ,
            endAngle : 0 ,
            min : 0 ,
            max : 240 ,
            splitNumber : 12 ,
            itemStyle : {
                color : '#58D9F9' ,
                shadowColor : 'rgba(0,138,255,0.45)' ,
                shadowBlur : 10 ,
                shadowOffsetX : 2 ,
                shadowOffsetY : 2
            } ,
            progress : {
                show : true ,
                roundCap : true ,
                width : 20
            } ,
            pointer : {
                icon : 'path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z' ,
                length : '75%' ,
                width : 16 ,
                offsetCenter : [ 0 , '5%' ]
            } ,
            axisLine : {
                roundCap : false ,
                lineStyle : {
                    width : 18
                }
            } ,
            axisTick : {
                show: false,
                splitNumber : 2 ,
                lineStyle : {
                    width : 2 ,
                    color : '#000'
                }
            } ,
            splitLine : {
                show: false,
                length : 12 ,
                lineStyle : {
                    width : 3 ,
                    color : '#999'
                }
            } ,
            axisLabel : {
                show: false,
                distance : 30 ,
                color : '#999' ,
                fontSize : 20
            } ,
            title : {
                show : false
            } ,
            detail : {
                backgroundColor : '#fff' ,
                borderColor : '#999' ,
                borderWidth : 2 ,
                width : '60%' ,
                lineHeight : 25 ,
                height : 30 ,
                borderRadius : 6 ,
                offsetCenter : [ 0 , '35%' ] ,
                valueAnimation : true ,
                formatter : function ( value ) {
                    return '{value|' + value.toFixed ( 0 ) + '}';
                } ,
                rich : {
                    value : {
                        fontSize : 30 ,
                        fontWeight : 'bolder' ,
                        color : '#777'
                    } ,
                    unit : {
                        fontSize : 20 ,
                        color : '#999' ,
                        padding : [ 0 , 0 , - 20 , 10 ]
                    }
                }
            } ,
            data : [
                {
                    value : 0
                }
            ]
        }
    ]
}
export const periodsOptions =[
    { id : "1" , days : "Today" } , { id : "2" , days : "Last 7 days" } , {
        id : "3" ,
        days : "Last 30 Days"
    }
]

export const sourceName={
    ProductViewed:"ProductViewed",
    OrderCreated:"OrderCreated",
    ShopViewed:"ShopViewed"
}