import { ISellerDataModel } from "@/Interfaces/DataModel/SellerDataModel";
import { ILoginDataModel } from "@/Interfaces/DataModel/LoginDataModel";
import { IUserCredentialDataModel } from "@/Interfaces/DataModel/UserCredentialsDataModel";
import { IResponseDataModel } from "@/Interfaces/DataModel/ResponseDataModel";
import { AuthApi } from "@/service/Common/ApiRoutes";
import { actionsConstant, getActionName } from "@/store/constants/userCredentials";
import ApiService from "../Common/ApiService";
import AuthStore from '@/store/'
import store from "@/store"

function Login(data: ILoginDataModel): Promise<IResponseDataModel<IUserCredentialDataModel>> {
   const { url, method }: { url: string, method: string } = AuthApi.Login
    return ApiService.SendRequest(url, method, { data })
}


function SignUp(data: ILoginDataModel): Promise<IResponseDataModel<IUserCredentialDataModel>> {
   const { url, method }: { url: string, method: string } = AuthApi.SignUp
   return ApiService.SendRequest(url, method, { data })
}


function ResetPassword(data: ILoginDataModel): Promise<IResponseDataModel<IUserCredentialDataModel>> {
   const { url, method }: { url: string, method: string } = AuthApi.ResetPassword
   return ApiService.SendRequest(url, method, { data })
}


function OtpRequest(data: ILoginDataModel,status:string): Promise<IResponseDataModel<string>> {
   const { url, method }: { url: string, method: string } = AuthApi.OtpRequest
   return ApiService.SendRequest(url, method, {query:{purpose:status}, data })
}


function SetCredentials(resp: any) {
    const actionName = getActionName(actionsConstant.setAuth)
    AuthStore.dispatch(actionName, resp)
    const token : string = store.getters['userCred/getAccessToken']
    ApiService.setToken(token)
}


export { Login, SetCredentials, SignUp, ResetPassword, OtpRequest }