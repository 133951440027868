<template>
  <div class="custom-suggestion">
    <div class=""  :class="isDarkMode ? 'tag-inputDarkTheme' : 'tag-input'">
      <!-- Display tags as editable spans inside the input field -->
      <span
        v-for="(tag, index) in tags"
        :key="index"
        :class="isDarkMode?'tagDarkTheme':'tag'"
        @blur="handleTagEdit(index, $event)"
      >
        {{ tag }}
        <span class="close-icon" @click="removeTag(index)">&times;</span>
      </span>
      <!-- Input field for entering new tags -->
      <input
        v-if="tags.length < 2"
        v-model="inputValue"
        @input="handleInput"
        @keydown.down.prevent="highlightNext"
        @keydown.up.prevent="highlightPrevious"
        @keydown.enter.prevent="handleEnter"
        placeholder="Enter tag..."
         :class="isDarkMode ? 'custom-input' : 'form-control'"
      />
      <div v-if="showError" class="error-message">Format must be 'word = word'</div>
    </div>

    <!-- Suggestions list when input is focused and no tags are displayed -->
    <ul v-if="showSuggestions && tags.length < 2" :class="isDarkMode ? 'suggestionsDrakTheme' : 'suggestions'">
      <li
        v-for="(suggestion, index) in filteredSuggestions"
        :key="index"
        :class="{ active: index === highlightedIndex }"
        @mousedown="selectSuggestion(suggestion)"
      >
        {{ suggestion }}
      </li>
    </ul>
    <ArgonButton
      class="btn-icon ms-2"
      color="white"
      :class="isDarkMode ? 'text-white' : 'text-primary'"
      :variant="isDarkMode ? 'outline' : 'fill'"
      @click="handleSearch"
    >
      <span class="btn-inner--text">Apply Search</span>
    </ArgonButton>
  </div>
</template>

<script setup>
import { ref, computed, defineEmits } from "vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { useStore } from "vuex";

const store = useStore();
const inputValue = ref("");
const suggestions = ref(["Name", "Email", "Contact Number"]);
let highlightedIndex = ref(-1);
const tags = ref([]);
const showError = ref(false);
const showSuggestions = ref(false); // Ensure to initialize as a reactive reference

const isDarkMode = computed(() => store.state.argon.darkMode);
const emit = defineEmits(["searchWithTagkey"]);

function handleInput(event) {
  inputValue.value = event.target.value;
  highlightedIndex.value = -1; // Reset highlighted index when input changes
  showError.value = false; // Hide error message on new input

  // Show suggestions when input is not empty and no tags are displayed
  if (inputValue.value.trim() !== "" && tags.value.length < 2) {
    showSuggestions.value = true;
  } else {
    showSuggestions.value = false;
  }
}

function selectSuggestion(suggestion) {
  // Append ' = ' (space before and after '=') to the selected suggestion before setting it to inputValue
  inputValue.value = suggestion + " = ";
  highlightedIndex.value = -1; // Reset highlighted index after selection
  showSuggestions.value = false; // Hide suggestions after selection
}

function handleEnter() {
  if (inputValue.value.trim() !== "") {
    const tagValue = inputValue.value.trim();
    if (isValidTag(tagValue) && tags.value.length < 2) {
      tags.value.push(tagValue);
      clearInput();
    } else {
      showError.value = true; // Show error message if tag format is invalid or tag limit exceeded
    }
  } else {
    showError.value = true; // Show error if input is empty
  }
}

function removeTag(index) {
  tags.value.splice(index, 1);
  showSuggestions.value = false; // Ensure suggestions are shown after removing a tag
  emit("searchWithTagkey", tags.value);
}

function handleTagEdit(index, event) {
  const editedTag = event.target.innerText.trim();
  if (isValidTag(editedTag)) {
    tags.value[index] = editedTag;
  } else {
    showError.value = true; // Show error message if edited tag format is invalid
  }
}

function highlightNext() {
  if (highlightedIndex.value < filteredSuggestions.value.length - 1) {
    highlightedIndex.value++;
  }
}

function highlightPrevious() {
  if (highlightedIndex.value > 0) {
    highlightedIndex.value--;
  }
}

const filteredSuggestions = computed(() => {
  const regex = new RegExp(inputValue.value.trim(), "i");
  return suggestions.value.filter((suggestion) => regex.test(suggestion));
});

function clearInput() {
  inputValue.value = "";
  highlightedIndex.value = -1;
  showSuggestions.value = false; // Close suggestions after clearing input
}

function isValidTag(value) {
  return /^\s*\w+[\w\s\-!@#$%^&*()+=|\\[\]{};':",.<>/?]*\s*=\s*[\w\s\-!@#$%^&*()+=|\\[\]{};':",.<>/?]*\s*$/.test(value);
}

function handleSearch() {
  emit("searchWithTagkey", tags.value);
}
</script>

<style scoped>
.custom-suggestion {
  position: relative;
}

.tag-input {
  display: inline-flex;
  align-items: center;
  width: 90%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.tag-inputDarkTheme {
  display: inline-flex;
  align-items: center;
  width: 90%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #051139;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.tagDarkTheme {
  background-color: #142252;
  color: #fff;
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
}
.tag {
  background-color: #1197ef;
  color: #fff;
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
}


.tag .close-icon {
  cursor: pointer;
  margin-left: 0.5rem;
  font-weight: bold;
}

.error-message {
  color: red;
  margin-top: 0.5rem;
}

.suggestions {
  position: absolute;
  z-index: 1000;
  background-color: #fcfcfc;
  border: 1px solid #ccc;
  list-style-type: none;
  margin-top: 30;
  margin-bottom: 60;
  padding: 0;
  width: 40%;
  max-height: 200px;
  overflow-y: auto;
}
.suggestionsDrakTheme {
  position: absolute;
  z-index: 1000;
  background-color: #051139;
  border: 1px solid #ccc;
  list-style-type: none;
  margin-top: 30;
  margin-bottom: 60;
  padding: 0;
  width: 40%;
  max-height: 200px;
  overflow-y: auto;
}

.suggestions li {
  padding: 10px;
  cursor: pointer;
}

.suggestions li.active {
  background-color: #a03131;
}

.form-control {
  flex: 1;
  border: none;
  outline: none;
  background: transparent;
}
</style>
