import { IResponseDataModel } from "@/Interfaces/DataModel/ResponseDataModel";
import {
    counterChartGraphApi,
    gauageChartGraphApi,
    histogramChartGraphApi,
    pointsChartGraphApi,
    templateDownload
} from "./apiService/AnalyticsService";
import {
    IAnalyticsCounterViewDataModel ,
    IAnalyticsGaugeViewDataModel ,
    IAnalyticsHistogramViewDataModel ,
    IAnalyticsPointsViewDataModel ,
    IAnalyticsRequestDataModel , ICatalogue_summary , ICatalogues ,
    ICataloguesCount , ICatalogueUsageCount ,
    ICatalogueViewModel , IChartTemplateViewModel ,
    ICounterCardDataModel ,
    IPeriodViewModel ,
    ITemplateDataModel,
    IStoresDataModel
} from "@/Interfaces/DataModel/AnalyticsDataModel";
import { GetAllStore, StoreCount } from "./apiService/StoreService";
import { CustomerCount } from "./apiService/CustomerService";
import { CatalogueCount, GetAllCatalogue } from "./apiService/CatalogueService";
import { CommodityCount } from "./apiService/CommodityService";
import { CouponBookletCount } from "./apiService/CouponService";
import { orderCount } from "@/service/apiService/OrderService";
import { UserCount } from "@/service/apiService/UserService";
import { countGraph, DashboardTemplateUrl, dateFilter, sourceName } from "@/views/configs/analayticsConfig"
import { ICatalogueDataModel } from "@/Interfaces/DataModel/CatalogueDataModel";
import { IStoreDataModel } from "@/Interfaces/DataModel/StoreDataModel";


export async function fetchGaugeChartData(data: IAnalyticsRequestDataModel, sellerId: string): Promise<IAnalyticsGaugeViewDataModel> {
    try {
        const graphResult = await gauageChartGraphApi(data, sellerId) as IResponseDataModel<IAnalyticsGaugeViewDataModel>;
        return graphResult.result;
    }
    catch (error) {
        return null;
    }
}

export async function fetchCounterChartData(data: IAnalyticsRequestDataModel, sellerId: string): Promise<IAnalyticsCounterViewDataModel> {
    try {
        const graphResult = await counterChartGraphApi(data, sellerId) as IResponseDataModel<IAnalyticsCounterViewDataModel>
        return graphResult.result;
    }
    catch (error) {
        return null;
    }
}

export async function fetchHistogramChartData(requestData: IAnalyticsRequestDataModel, sellerId: string): Promise<IAnalyticsHistogramViewDataModel> {
    try {
        const graphResult = await histogramChartGraphApi(requestData, sellerId) as IResponseDataModel<IAnalyticsHistogramViewDataModel>
        return graphResult.result
    }
    catch (error) {
        return null
    }
}

export async function fetchPointChartData(data:IAnalyticsRequestDataModel, sellerId:string): Promise<IAnalyticsPointsViewDataModel> {
    try {
        const graphResult = await pointsChartGraphApi(data, sellerId) as IResponseDataModel<IAnalyticsPointsViewDataModel>
        return graphResult.result;
    }
    catch (error){
        return null;
    }
}

export async function fetchAllCounts(sellerId: string, counterData:IChartTemplateViewModel, emptyCountCardContent: ICounterCardDataModel[]): Promise<any> {
    const [newStoreData, newCustomerData, newCatalogueData, newCommodityData, newCouponData, newUserData] = await Promise.all([
        fetchingCount(sellerId),
        fetchingCustomerCount(sellerId),
        fetchingCatalogueCount(sellerId),
        fetchingCommodityCount(sellerId),
        fetchingCouponCount(sellerId),
        fetchingUserCount(sellerId)

    ]);

    const dataMap = {
        [countGraph.StoreCount]: newStoreData,
        [countGraph.CustomerCount]: newCustomerData,
        [countGraph.CatalogueCount]: newCatalogueData,
        [countGraph.CommodityCount]: newCommodityData,
        [countGraph.CouponCount]: newCouponData,
        [countGraph.UserCount]: newUserData
    };


    const updatedDataset = counterData.dataset.map(item => (
        {
            ...item,
            description: item.description,
            data: dataMap[item.description]
        }
    ));

    counterData.dataset = updatedDataset;
    return counterData;


}

export async function fetchingCount(sellerId: string): Promise<number> {

    const data = await StoreCount(sellerId)
    return data.result
}

export async function fetchingCustomerCount(sellerId: string): Promise<number> {

    const data = await CustomerCount(sellerId)
    return data.result
}

export async function fetchingCatalogueCount(sellerId: string): Promise<number> {

    const data = await CatalogueCount(sellerId)
    return data.result
}

export async function fetchingCommodityCount(sellerId: string): Promise<number> {

    const data = await CommodityCount(sellerId)
    return data.result
}

export async function fetchingCouponCount(sellerId: string): Promise<number> {

    const data = await CouponBookletCount(sellerId)
    return data.result
}

export async function fetchingOrderCount(sellerId: string): Promise<number> {
    const data = await orderCount(sellerId)
    return data.result
}

export async function fetchingUserCount(sellerId: string): Promise<number> {
    const data = await UserCount(sellerId)
    return data.result
}

export function convertIntoCounterChart(dynamicTemplateDataModel: ITemplateDataModel):IChartTemplateViewModel {
    const filterByType = (array, type) => {
        const result = array.panels.filter(item => {
            return item.type === type;
        });
        return result;
    };

    // filtering counter chart only now
    const filteredArray = filterByType(dynamicTemplateDataModel, 'counter');
    const transformedArray = transformData(filteredArray);
    return {
        title: dynamicTemplateDataModel.title,
        description: dynamicTemplateDataModel.description,
        dataset: transformedArray
    };

}

const transformData = (array) => {
    return array.map(item => (
        {
            title: item.title,
            description: item.description,
            url: item.datasource?.request?.route || '',
            method: item.datasource?.request?.method || '',
            data: 0,
            image: "",
            profeature: false
        }
    ));
};

export async function FetchDynamicGraph(): Promise<ITemplateDataModel> {
    const fileUrl = DashboardTemplateUrl.url;
    const response = await templateDownload(fileUrl);
    return response;
}

export async function loadCatalogueData(sellerId: string): Promise<{
    catalogue_summary: ICatalogue_summary[];
    catalogues: ICatalogues[];
    cataloguesCount: ICataloguesCount;
    publishedCatalogueCount: ICatalogueUsageCount;
    unpublishedCatalogueCount: ICatalogueUsageCount;
}> {
    const data = await GetAllCatalogue(sellerId, 100, "")
    const cataloguesCount = countCatalogues(data.result)
    const publishedCatalogueCount = getPublishedCatalogueSummary(data.result, 'published');
    const unpublishedCatalogueCount = getPublishedCatalogueSummary(data.result, 'unpublished');
    const catalogue_summary: ICatalogue_summary[] = getCatalogueSummary(data.result);
    const catalogues = data.result.map(item => (
        {
            catalogueId: item.catalogueId,
            catalogueName: item.catalogueName
        }
    ));

    return { cataloguesCount, publishedCatalogueCount, unpublishedCatalogueCount, catalogues, catalogue_summary }
}

export async function loadStoreData(sellerId: string):Promise<IStoresDataModel[]> {
    const data = await GetAllStore(sellerId);
    const stores = data.result.map(item => (
        {
            storeId: item.storeId,
            storeName: item.storeName
        }
    ));
    return stores
}

function countCatalogues(catalogues: ICatalogueDataModel[]): ICataloguesCount {
    const counts: ICataloguesCount = {
        publishedCount: 0,
        unpublishedCount: 0,
        submittedToPublishedCount: 0,
        TotalCount: catalogues.length
    };

    catalogues.forEach((catalogue: ICatalogueDataModel) => {
        if (catalogue.status === 'published') {
            counts.publishedCount++;
        }
        else if (catalogue.status === 'unpublished') {
            counts.unpublishedCount++;
        }
        else {
            counts.submittedToPublishedCount++;
        }
    });

    return counts;
}

function getPublishedCatalogueSummary(catalogues: ICatalogueDataModel[], status: string): ICatalogueUsageCount {
    const publishedCatalogues = catalogues.filter(catalogue => catalogue.status === status);
    let totalCommodities = 0;
    const storeSet = new Set(); // Use a set to keep unique stores

    publishedCatalogues.forEach(catalogue => {
        totalCommodities += Object.keys(catalogue.commodities).length;
        catalogue.stores.forEach(store => storeSet.add(store));
    });

    return {
        CataloguesCount: publishedCatalogues.length,
        totalCommodities,
        totalStores: storeSet.size
    };
}

export async function catalogueViewsPercepetive(sellerId: string, catalogueData: ICatalogue_summary, selectedPeriod: string) {
    const time = getPeriodValue(selectedPeriod) as IPeriodViewModel
    const dataModel: IAnalyticsRequestDataModel = {
        sources: [
            sourceName.ProductViewed
        ],
        groupBy: [],
        orderBy: [],
        filters: [
            `CatalogueId = ${catalogueData.catalogueId}`
        ],
        timeWindowStart: time.timeWindowStart,
        timeWindowEnd: time.timeWindowEnd
    }

    const data = await fetchGaugeChartData(dataModel, sellerId)
    return data
}

export async function orderCountAganistCatlogueViewsPercepetive(sellerId: string, catalogueData: ICatalogue_summary, selectedPeriod: string) {
    const time = getPeriodValue(selectedPeriod) as IPeriodViewModel
    const dataModel = {
        sources: [
            sourceName.OrderCreated
        ],
        groupBy: [],
        orderBy: [],
        filters: [
            `CatalogueId = ${catalogueData.catalogueId}`
        ],
        timeWindowStart: time.timeWindowStart,
        timeWindowEnd: time.timeWindowEnd
    }
    const data = await fetchCounterChartData(dataModel, sellerId)
    return data
}

export async function storeViewsPercepetive(sellerId: string, selectedStore:IStoresDataModel, selectedPeriod: string) {
    const time = getPeriodValue(selectedPeriod)
    const dataModel = {
        sources: [
            sourceName.ShopViewed
        ],
        groupBy: [],
        orderBy: [],
        filters: [
            `shops = ${selectedStore.storeId}`
        ],
        timeWindowStart: time.timeWindowStart,
        timeWindowEnd: time.timeWindowEnd
    }
    const data = await fetchGaugeChartData(dataModel, sellerId)
    return data
}

function getCatalogueSummary(catalogues: ICatalogueDataModel[]): ICatalogue_summary[] {
    const today = new Date();
    return catalogues.map(catalogue => {
        const commoditiesCount = Object.keys(catalogue.commodities).length;
        const storeCount = new Set(catalogue.stores).size;
        const validToDate = new Date(catalogue.validToDate);
        const todayTimestamp = today.getTime();
        const validToDateTimestamp = validToDate.getTime();
        const daysLeft = Math.ceil((
            validToDateTimestamp - todayTimestamp
        ) / (
                1000 * 60 * 60 * 24
            ));

        return {
            catalogueId: catalogue.catalogueId,
            catalogueName: catalogue.catalogueName,
            commoditiesCount,
            storeCount,
            daysLeft,
            orderCount: 0

        };
    });
}

function getPeriodValue(filterData: string): IPeriodViewModel {
    const requestData = {
        timeWindowStart: "",
        timeWindowEnd: ""
    }

    if (filterData === dateFilter.lastWeek) {
        const currentDate = new Date(Date.now());
        currentDate.setDate(currentDate.getDate() - 7);
        requestData.timeWindowStart = currentDate.toISOString();
    }
    if (filterData === dateFilter.Today) {
        const currentDate = new Date(Date.now());
        currentDate.setDate(currentDate.getDate());
        requestData.timeWindowStart = currentDate.toISOString();
    }
    if (filterData === dateFilter.lastMonth) {
        const currentDate = new Date(Date.now());
        const thirtyDaysAgo = new Date(currentDate);
        thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
        requestData.timeWindowStart = thirtyDaysAgo.toISOString();
    }
    if (filterData === dateFilter.lastYear) {
        const currentYear = new Date().getFullYear();
        const startDate = new Date(Date.UTC(currentYear, 0, 1, 0, 0, 0));
        requestData.timeWindowStart = startDate.toISOString();
    }
    const currentDate = new Date();
    requestData.timeWindowEnd = currentDate.toISOString();
    return requestData
}