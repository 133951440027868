import { createApp } from "vue";
import App from "./App.vue";
import store from "./store/";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import "./assets/scss/argon-dashboard.scss";
// import VueTilt from "vue-tilt.js";
import VueSweetalert2 from "vue-sweetalert2";

import Toast, { PluginOptions } from "vue-toastification";
import "vue-toastification/dist/index.css";

import  BootstrapVue3 from 'bootstrap-vue-3'
import CardPlugin from 'bootstrap-vue-3'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import { logger } from "./service/Common/LoggerService";

const options: PluginOptions = {
    newestOnTop: true,
    maxToasts: 2,
    transition: 'enter', // | "leave" | "move",
    timeout: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true
};

const appInstance = createApp(App).use(router);
appInstance.use(store);
appInstance.use(router);
appInstance.use(BootstrapVue3)
appInstance.use(CardPlugin)
appInstance.use(Toast, options);
appInstance.mount("#app");

logger.info("Starting seller ui")