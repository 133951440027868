function searchViewModelToCatalogueViewModel(searchResultItem, sellerId, userId, regionId) {
    return {
        regionId,
        catalogueId:searchResultItem.CatalogueId,
        sellerId,
        userId,
        catalogueName:searchResultItem.CatalogueName,
        status:searchResultItem.Status,
        stores:searchResultItem.Stores,
        tags:searchResultItem.Tags,
    }
}
export { searchViewModelToCatalogueViewModel }