<template>
  <div class="mt-4 card h-100 mt-md-0">
    <div class="p-3 pb-0 card-header">
      <div class="d-flex align-items-center">
        <h6>{{ title }}</h6>
      </div>
    </div>
    <div class="px-3 pt-0 pb-2 card-body">
      <div class="p-0 table-responsive">
        <table class="table mb-0 align-items-center justify-content-center">
          <thead>
            <tr>
              <th
                v-for="(header, index) of headers"
                :key="index"
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                {{ header }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="({ storeName, salesValue, orderCount }, index) of pages"
              :key="index"
            >
              <td>
                <p class="mb-0 text-sm font-weight-bold">
                   {{ storeName }}
                </p>
              </td>
              <td>
                <p class="mb-0 text-sm font-weight-bold">{{ salesValue }}</p>
              </td>
              <td>
                <p class="mb-0 text-sm font-weight-bold">{{ orderCount }}</p>
              </td>
              <td>
                <!-- <p class="mb-0 text-sm font-weight-bold">{{ rate }}</p> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AnalyticsCard",
  props: {
    title: {
      type: String,
      default: "",
    },
    headers: {
      type: Array,
      required: true,
    },
    pages: {
      type: Array,
      required: true,
      storeName: String,
      salesValue: [Number, String],
      orderCount: String,
      rate: String,
    },
  },
};
</script>
