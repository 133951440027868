import { IStoreDataModel } from "@/Interfaces/DataModel/StoreDataModel";


interface IStores {
    storeName: string,
    storeId: string,
    deliveryType:string[],
}

interface IstoresState {
    stores: IStores[],
    lastUpdated: string,
    
}

function defaultState(): IstoresState {
    return {
        stores: [],
        lastUpdated: ''
    }
};

export default {

    namespaced: true,

    state: defaultState(),

    mutations: {

        setStores(state: IstoresState, payload: IStores[]) {
            state.stores = payload
        },

        removeStore(state: IstoresState, storeId: string) {
            state.stores = state.stores.filter(store => store.storeId !== storeId)
        },

        updateStore(state: IstoresState, storeData: IStoreDataModel) {
            state.stores = state.stores.map(store => {
                if (store.storeId === storeData.storeId) {
                    store.storeName = storeData.storeName,
                    store.deliveryType=storeData.deliveryType
                }
                return store
            })
        },

        addStore(state: IstoresState, storeData: IStoreDataModel) {
            state.stores.push({
                storeName: storeData.storeName,
                storeId: storeData.storeId,
                deliveryType:storeData.deliveryType
            })
        },

        resetState(state: any) {
            Object.assign(state, defaultState())      
        }

    },

    actions: {

        setStores(ctx: any, stores: IStoreDataModel[]) {
            const storesModel: IStores[] = stores.map(store => ({ storeName: store.storeName, storeId: store.storeId,deliveryType:store.deliveryType }))
            ctx.commit('setStores', storesModel)
        },

        removeStore(ctx: any, storeId: string) {
            ctx.commit('removeStore', storeId)
        },

        updateStore(ctx: any, store: IStoreDataModel) {
            ctx.commit('updateStore', store)
        },

        addStore(ctx: any, store: IStoreDataModel) {
            ctx.commit('addStore', store)
        },

    },
    getters: {

        getStores: (state: any) => state.stores,

    },
}
