enum UserDataModelKeys {
    sellerId       = 'sellerId',
    storeId        = 'storeId', 
    userId         = 'userId',
    regionId       = 'regionId',
    address        = 'address',
    cartId         = 'cartId',
    city           = 'city',
    country        = 'country',
    district       = 'district',
    email          = 'email',
    IsLocked       = 'IsLocked',
    name           = 'name',
    permission     = 'permission',
    phoneNumber    = 'phoneNumber',
    pinCode        = 'pinCode',
    secondaryPhone = 'secondaryPhone',
    state          = 'state',
    status         = 'status',

    IsSaved        = 'IsSaved'
  }

interface IUserDataModel {
  [UserDataModelKeys.sellerId]: string;
  [UserDataModelKeys.storeId]: string;
  [UserDataModelKeys.userId]: string;
  [UserDataModelKeys.regionId]: string;
  [UserDataModelKeys.address]: string;
  [UserDataModelKeys.cartId]: string;
  [UserDataModelKeys.city]: string;
  [UserDataModelKeys.country]: string;
  [UserDataModelKeys.district]: string;
  [UserDataModelKeys.email]: string;
  [UserDataModelKeys.IsLocked]: boolean;
  [UserDataModelKeys.name]: string;
  [UserDataModelKeys.permission]: string[];
  [UserDataModelKeys.phoneNumber]: string;
  [UserDataModelKeys.pinCode]: string;
  [UserDataModelKeys.secondaryPhone]: string;
  [UserDataModelKeys.state]: string;
  [UserDataModelKeys.status]: string;
}

interface IUserActiveViewModel {
  [UserDataModelKeys.sellerId]: string;
  [UserDataModelKeys.storeId]: string;
  [UserDataModelKeys.userId]: string;
  [UserDataModelKeys.regionId]: string;
  [UserDataModelKeys.address]: string;
  [UserDataModelKeys.city]: string;
  [UserDataModelKeys.country]: string;
  [UserDataModelKeys.district]: string;
  [UserDataModelKeys.email]: string;
  [UserDataModelKeys.IsLocked]: boolean;
  [UserDataModelKeys.name]: string;
  [UserDataModelKeys.permission]: string[];
  [UserDataModelKeys.phoneNumber]: string;
  [UserDataModelKeys.pinCode]: string;
  [UserDataModelKeys.state]: string;
  [UserDataModelKeys.status]: string;
  [UserDataModelKeys.cartId]: string;
  [UserDataModelKeys.secondaryPhone]: string;
  [UserDataModelKeys.IsSaved]: string;
}
export { IUserActiveViewModel, IUserDataModel, UserDataModelKeys };
