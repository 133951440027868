import Vuex, { Payload, Store } from 'vuex'
import VuexPersistence from 'vuex-persist'
import { nameSpace as userCredName } from "../constants/userCredentials";
import { TempIArgonModel } from '../argon'
import { IUserCredentialDataModel } from "@/Interfaces/DataModel/UserCredentialsDataModel";
export interface State {
    argon: TempIArgonModel,
    [userCredName]: IUserCredentialDataModel,
    catelogue: any,
    commodity: any,
    stores: any
  }

  const vuexLocal = new VuexPersistence<State>({
    storage: window.localStorage,
    reducer: (state: State) => ({ 
      [userCredName]: state[userCredName]
      }),
  })


 export { vuexLocal } 