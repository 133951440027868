import { IOrderDataModel, IOrderViewModel } from "@/Interfaces/DataModel/OrderDataModel";
import ApiService from "../Common/ApiService";
import { OrderApi } from "../Common/ApiRoutes";
import { IResponseDataModel } from "@/Interfaces/DataModel/ResponseDataModel";

function GetAllOrders(sellerId: string, query?: any): Promise<IResponseDataModel<IOrderDataModel[]>> {
    const { url, method }: { url: string, method: string } = OrderApi.GetAllOrder(sellerId)
    return ApiService.SendRequest(url, method,{ query })

}
function GetAOrder(sellerId: string, orderId: string): Promise<IResponseDataModel<IOrderDataModel>> {
    const { url, method }: { url: string, method: string } = OrderApi.GetAOrder(sellerId, orderId)
    return ApiService.SendRequest(url, method,{ query: { currentStatus :""} })

}
function UpdateAOrder(sellerId: string, orderId: string, data: IOrderDataModel): Promise<IResponseDataModel<IOrderDataModel>> {
    const { url, method }: { url: string, method: string } = OrderApi.UpdateAOrder(sellerId, orderId)
    return ApiService.SendRequest(url, method, { data })

}

function CreateOrderInvoice(sellerId: string, storeId: string, data: IOrderDataModel ) : Promise<IResponseDataModel<string>> {
    const { url, method }: { url: string, method: string } = OrderApi.CreateInvoice(sellerId, storeId)
    return ApiService.SendRequest(url, method, { data })
}
function orderCount (sellerId:string): Promise<IResponseDataModel<number>> {
    const {url,method} :{url:string,method:string} = OrderApi.OrderCount(sellerId);
    return ApiService.SendRequest(url,method)
}

export {
    GetAllOrders,
    GetAOrder,
    UpdateAOrder,
    CreateOrderInvoice,
    orderCount
}