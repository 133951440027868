const inputFieldConstants = {
  name: {
    id: "storeName",
    label: "Store Name",
    placeHolder: "Store Name",
    componentName: "Store Name",
  },

  email: {
    id: "email",
    label: "Email",
    placeHolder: "Email",
    componentName: "Email",
  },

  website: {
    id: "websiteUrl",
    label: "Website",
    placeHolder: "Store Website",
    componentName: "Website",
  },

  gstNumber: {
    id: "gstnumber",
    label: "GSTIN number",
    placeHolder: "Store GSTIN number",
    componentName: "GSTIN Number",
  },

  phoneNumber: {
    id: "phoneNumber",
    label: "Phone Number",
    placeHolder: "Phone Number",
    componentName: "Phone Number",
  },

  catagories: {
    id: "catagories",
    label: "Catagories",
    placeHolder: "",
    componentName: "Catagories",
  },
  delivery: {
    id: "delivery",
    label: "Delivery Type",
    placeHolder: "",
    componentName: "Delivery",
  },

  managerName: {
    id: "managerName",
    label: "Manager Name",
    placeHolder: "Store Manager Name",
    componentName: "Manager Name",
  },

  managerPhoneNumber: {
    id: "managerPhoneNumber",
    label: "Phone Number",
    placeHolder: "Manager Phone Number",
    componentName: "Manager Phone Number",
  },

  address: {
    id: "address",
    label: "Address",
    placeHolder: "store Address",
    componentName: "Address",
  },

  geoCode: {
    id: "geocode",
    label: "GeoCode",
    placeHolder: "Store GeoCode",
    componentName: "Geocode",
  },

  city: {
    id: "city",
    label: "City",
    placeHolder: "City",
    componentName: "City",
  },

  district: {
    id: "district",
    label: "District",
    placeHolder: "District",
    componentName: "District",
  },

  pinCode: {
    id: "pincode",
    label: "Pincode",
    placeHolder: "Pincode",
    componentName: "Pincode",
  },

  state: {
    id: "state",
    label: "State",
    placeHolder: "State",
    componentName: "State",
  },

  country: {
    id: "country",
    label: "Country",
    placeHolder: "Country",
    componentName: "Country",
  }

};

export { inputFieldConstants };
