import { IStoreDataModel } from "@/Interfaces/DataModel/StoreDataModel";
import ApiService from "../Common/ApiService";
import { StoreApi } from "../Common/ApiRoutes";
import { IResponseDataModel } from "@/Interfaces/DataModel/ResponseDataModel";

function CreateAStore(sellerId: string, data: IStoreDataModel): Promise<IResponseDataModel<IStoreDataModel>> {
     const { url, method }: { url: string, method: string } = StoreApi.CreateAStore(sellerId)
     return ApiService.SendRequest(url, method, { data })
}

function GetAllStore(sellerId: string): Promise<IResponseDataModel<IStoreDataModel[]>> {
     const { url, method }: { url: string, method: string } = StoreApi.GetAllStores(sellerId)
     return ApiService.SendRequest(url, method)
}

function GetAStore(sellerId: string, storeId: string): Promise<IResponseDataModel<IStoreDataModel>> {
     const { url, method }: { url: string, method: string } = StoreApi.GetAStore(sellerId, storeId)
     return ApiService.SendRequest(url, method)
}

function UpdateAStore(sellerId: string, storeId: string, data: IStoreDataModel): Promise<IResponseDataModel<IStoreDataModel>> {
     const { url, method }: { url: string, method: string } = StoreApi.UpdateAStore(sellerId, storeId)
     return ApiService.SendRequest(url, method, { data })
}

function DeleteAStore(sellerId: string, storeId: string): Promise<IResponseDataModel<IStoreDataModel>> {
     const { url, method }: { url: string, method: string } = StoreApi.DeleteAStore(sellerId, storeId)
     return ApiService.SendRequest(url, method)
}
function StoreCount(sellerId: string): Promise<IResponseDataModel<any>> {
     const { url, method }: { url: string, method: string } = StoreApi.StoreCount(sellerId)
     return ApiService.SendRequest(url, method)
}


export {
    CreateAStore,
    GetAllStore,
    UpdateAStore,
    DeleteAStore,
    GetAStore,
    StoreCount
}