
import ApiService from "../Common/ApiService";
import { messageTemplateApi } from "../Common/ApiRoutes";
import { IResponseDataModel } from "@/Interfaces/DataModel/ResponseDataModel";


function getAllMessageTemplate(sellerId: string): Promise<IResponseDataModel<any>> {
    const { url, method }: { url: string, method: string } = messageTemplateApi.getAllMessageTemplate(sellerId);
    return ApiService.SendRequest(url, method)
}



function removeAMessageTemplate(sellerId: string,templateId:string): Promise<IResponseDataModel<any>> {
    const { url, method }: { url: string, method: string } = messageTemplateApi.removeMessageTemplate(sellerId,templateId);
    return ApiService.SendRequest(url, method)
}
function createAMessageTemplate(sellerId:string,TemplateDataModel:any):Promise<IResponseDataModel<any>>{
    const { url, method }: { url: string, method: string } = messageTemplateApi.createMessageTemplate(sellerId);
    return ApiService.SendRequest(url, method,{data:TemplateDataModel})
}
function updateAMessageTemplate(sellerId:string,TemplateDataModel:any):Promise<IResponseDataModel<any>>{
    const { url, method }: { url: string, method: string } = messageTemplateApi.updateMessageTemplate(sellerId,TemplateDataModel.templateId);
    return ApiService.SendRequest(url, method,{data:TemplateDataModel})
}

export {getAllMessageTemplate,removeAMessageTemplate,createAMessageTemplate,updateAMessageTemplate}