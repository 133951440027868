import { ICommodityDataModel } from "@/Interfaces/DataModel/CommodityDataModel";
import { IResponseDataModel } from "@/Interfaces/DataModel/ResponseDataModel"
import { ISearchViewModel } from "@/Interfaces/DataModel/SearchDataModel";
import { CommodityApi, CsvFile } from "../Common/ApiRoutes";
import ApiService from "../Common/ApiService";
import axios from "axios";


function CreateACommodity(sellerId: string, data: ICommodityDataModel): Promise<IResponseDataModel<ICommodityDataModel>> {
    const { url, method }: { url: string, method: string } = CommodityApi.CreateACommodity(sellerId);
    return ApiService.SendRequest(url, method, { data })
}
function GetAllCommodities(sellerId: string, page = 0): Promise<IResponseDataModel<ICommodityDataModel[]>> {
    const { url, method }: { url: string, method: string } = CommodityApi.GetAllCommodities(sellerId);
    return ApiService.SendRequest(url, method, { query: { page } })
}

function SearchCommodities(sellerId: string, searchKey: string, page,filter?:boolean): Promise<IResponseDataModel<ISearchViewModel<any>>> {
    const isglobal = (filter === false) ? filter : '';
    const { url, method }: { url: string, method: string } = CommodityApi.SearchCommodities(sellerId);
    return ApiService.SendRequest(url, method, { query: { searchKey, page,isglobal} })
}

function GetACommodity(sellerId: string, commodityId: string, isGlobalCommodity): Promise<IResponseDataModel<ICommodityDataModel>> {
    const { url, method }: { url: string, method: string } = CommodityApi.GetACommodity(sellerId, commodityId);
    return ApiService.SendRequest(url, method, { query: { fetchGlobalCommodity: isGlobalCommodity } })
}

function UpdateACommodity(sellerId: string, commodityId: string, data: ICommodityDataModel): Promise<IResponseDataModel<ICommodityDataModel>> {
    const { url, method }: { url: string, method: string } = CommodityApi.UpdateACommodity(sellerId, commodityId);
    return ApiService.SendRequest(url, method, { data })
}
function DeleteACommodity(sellerId: string, commodityId: string): Promise<IResponseDataModel<ICommodityDataModel>> {
    const { url, method }: { url: string, method: string } = CommodityApi.DeleteACommodity(sellerId, commodityId);
    return ApiService.SendRequest(url, method)
}
function CommodityCount(sellerId: string): Promise<IResponseDataModel<any>> {
    const { url, method }: { url: string, method: string } = CommodityApi.CommodityCount(sellerId);
    return ApiService.SendRequest(url, method)
}

async function csvDownloadFile(fileurl: string) {
    const {url,method,responseType}: {url:string,method:string,responseType:any} = CsvFile.downloadCsvFile(fileurl)
    const response:any = await axios({
        url,
        method,
        responseType
})
    return response.data;

}

export {
    CreateACommodity,
    GetAllCommodities,
    SearchCommodities,
    GetACommodity,
    UpdateACommodity,
    DeleteACommodity,
    csvDownloadFile,
    CommodityCount
}