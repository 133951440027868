<template>
    <div class="accordion" id="menuBar">
    
        <div class="accordion-item">
    <span class="accordion-header text-md" id="headingOne">
      <button
        class="accordion-button border-bottom font-weight-bold collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#CollapseSalesChannelPage"
        aria-expanded="false"
        aria-controls="CollapseSalesChannelPage"
      >
        Sales channels
      </button>
    </span>
    <div
      id="CollapseSalesChannelPage"
      class="accordion-collapse collapse show"
      aria-labelledby="headingOne"
      data-bs-parent="#menuBar"
    >
      <span class="accordion-body text-sm" id="headingTwo">
        <button
          class="accordion-button border-bottom font-weight-bold collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#CollapseSalesChannelPage"
          aria-expanded="false"
          aria-controls="CollapseSalesChannelPage"
        >
          Channels embed
        </button>
      </span>
      <div
        id="CollapseSalesChannelPage"
        class="accordion-collapse collapse show"
        aria-labelledby="headingOne"
        data-bs-parent="#menuBar"
      >
        <div class="accordion-body">
          <div class="accordion" id="sub-menuBar">
            <div class="accordion-item">
              <div class="input-group mb-3">
                <input
                  type="text"
                  szie="sm"
                  class="form-control"
                  placeholder="SEARCH"
                  aria-label="SEARCH"
                  aria-describedby="button-addon2"
                />
              </div>
              <span class="text-sm"
                ><strong
                  >Browse channels, built for Online Store themes. View
                  apps</strong
                ></span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> 
    </div>
</template>