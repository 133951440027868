function inviteCodeValidation(emailOrPhone: string, inviteCode: string): boolean {

    const currentDate = new Date().toISOString().split('T')[0];

    const combinedString = emailOrPhone + currentDate;

    return getHash(combinedString).substring(0, 6) == inviteCode

  }

function getHash(message: string): string {
    //ref: https://www.tutorialspoint.com/how-to-create-a-hash-from-a-string-in-javascript
    // https://www.geeksforgeeks.org/how-to-create-hash-from-string-in-javascript/

    let hash = 0;
    for (let i = 0; i < message.length; i++) {
      hash = (hash << 5) - hash + message.charCodeAt(i);
      hash |= 0;
    }
    return Math.abs(hash).toString().substring(0, 6);
  }


export { inviteCodeValidation }
