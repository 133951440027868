import { createStore } from "vuex";
import argon from './argon'
import userCred from './userCredentials'
import { nameSpace as userCredName } from "./constants/userCredentials";
import  { vuexLocal } from './plugins/persistState'
import catelogue from "./catelogue";
import commodity from "./commodity";
import customerProfile from "./customerProfile"
import integration from "./integration";
import collection from "./collection"
import stores from "./stores"
export default createStore({
    modules: {
        argon,
        [userCredName]: userCred,
        catelogue: catelogue,
        commodity: commodity,
        stores: stores,
        customerProfile:customerProfile,
        integration:integration,
        collection:collection
    },
    plugins: [vuexLocal.plugin]
})