



function keyAndValueExistsInObject(obj: any, key?: string, value?: any): boolean {
    if (!key) {
        const keys = Object.keys(obj)
        const values = Object.values(obj)
        return keys.every(key => !!key && obj[key] !== "" && values.includes(obj[key]))

    } else {
        return obj[key] === value
    }
}

function stringIsValid(str: any, requiredLength?: number): boolean {

    return (str && valueIsTypeString(str)) ? (requiredLength ? str.length >= requiredLength : true) : false
}

function valueIsTypeInt(value: any): boolean {
    return typeof value === 'number' || !(isNaN(value))
}

function valueIsTypeString(value: any): boolean {
    return typeof value === 'string'
}

function checkArrayType<T>(arr: T[], type: string): boolean {
    if (type === 'str') {
        return arr.every(valueIsTypeString)
    }
    else if (type === "int") {
        return arr.every(valueIsTypeInt)
    }
    else {
        console.log('Failed to find array type')
        return false
    }
}

function itemExistsInArray<T>(keyArray: T[], targetArray: T[]) {

    return targetArray.every(key => keyArray.includes(key))

}

function mergeObjects<T>(sourceObject: T, targetObject: T, replaceableKeysObject?: any) {

    const replaceableKeys = replaceableKeysObject ? Object.keys(replaceableKeysObject) : []

    for (const key in targetObject) {
        let sourceKey = key
        if (replaceableKeys.includes(key)) {
            sourceKey = replaceableKeysObject[key]
        }
        sourceObject[sourceKey] = targetObject[key]
    }
    return sourceObject
}
function removeDefaultLayout(store) {
    return store.commit("removeDefaultLayout");
}
function DefaultLayout(store) {
    return store.commit("setDefaultLayout");
}
function DarkMode(store) {
    return store.state.argon.darkMode
}

const modifyDate = (dateString: string): string => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" } as any
    const dateObj = new Date(dateString);
  
    const [month, date, year] = dateObj.toLocaleDateString("en", options).split("/");
    const formattedTime = dateObj.toLocaleTimeString("en-US", { hour12: false }).slice(0, -3)
    return `${year}-${month}-${date} ${formattedTime}`;
  };

export function setNewItemToTop(items: any) {
    const indexOfNewItems = (items || []).findIndex(item => item.id == "")
    if(indexOfNewItems > 0) {
      items.unshift(items.splice(indexOfNewItems, 1)[0])
    }
    return items
}

export function capitalizeFirstLetter(word: string) {
    return word ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : "" 
}
export function validatePhoneNumber(phoneNumber) {
    // Regular expression for checking if the phone number contains only numeric characters and has exactly 10 digits
    const regex = /^[0-9]{10}$/;
    return regex.test(phoneNumber);
  }

export function validateEmail(email) {
    // Regular expression for email validation
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }
export {
    checkArrayType,
    stringIsValid,
    keyAndValueExistsInObject,
    itemExistsInArray,
    mergeObjects,
    DefaultLayout,
    DarkMode,
    removeDefaultLayout,
    modifyDate
}