import { ICatalogueTableActiveDataModel } from "@/Interfaces/DataModel/CatalogueDataModel";
import { merge } from "lodash";

export function catalogueActiveModelToTableViewModel(catalogueTableActiveDataModel: ICatalogueTableActiveDataModel) {
    const result = [];
    for (const commodityId in catalogueTableActiveDataModel.commodities) {
      result.push(
        merge(
          catalogueTableActiveDataModel.commodities[commodityId].reduce(
            (tableRowData, item: any) => {
              tableRowData[item.name] = item.value;
              return tableRowData;
            },
            {}
          ),
          { commodityId: commodityId }
        )
      );
    }
  
    return result;
  }