
import {
  Chart as ChartJS,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import { PolarArea } from "vue-chartjs";

ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);

export default {
  name: "App",
  components: {
    PolarArea,
  },
    props: {
      chartData: {
        type: Object,
        required: true,
        default: () => ({
          labels: ['January', 'February', 'March'],
          backgroundColor: '#53bfee',
          datasets: [{ data: [0, 0, 0] }]
        })
      }
    },
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
      },
     
    };
  },
};
