import { createStore, CommitOptions } from "vuex";

function defaultState() {
  return {
    activeCommodity: null,
    isCollectionSelected: false,
    activeCollectionTableData: null
  }
}

export default {

  namespaced: true,

  state: defaultState(),

  mutations: {

    

    updateIsCollectionSelected(state: any, payload: boolean) {
      state.isCollectionSelected = payload
    },

    updateActiveCollection(state: any, payload: any) {
      state.activeCollectionTableData = payload
    },
    resetState(state: any) {
      Object.assign(state, defaultState())      

    }

  },

  actions: {

  
    setIsCollectionSelected(ctx: any, payload: boolean) {
      ctx.commit("updateIsCollectionSelected", payload)
    },
    setActiveCollection(ctx: any, payload: boolean) {
      console.log(payload)
      ctx.commit("updateActiveCollection", payload)
    }
  },
  getters: {

  
    getIsCollectionSelected: (state: any) => state.isCollectionSelected,
    getActiveCollection: (state: any) => state.activeCollectionTableData,

  },
}
