import { ITemplateViewModel } from "@/Interfaces/DataModel/TemplateDataModel"


export function ToEmptyMessageTemplateListViewModel(sellerId,regionId,userId):ITemplateViewModel{
    const emptyModel={
        sellerId,
        regionId,
        userId,
        templateId:"",
        templateName:"",
        body:"",
        category:"",
        status:"",
        broadcastName:"", footer:"", button:{name:"",value:""}, lastUpdate:"",
        saved:'NEW'
    }
    return emptyModel
}