import { method } from "lodash";

enum ApiMethods {
  POST = "POST",
  GET = "GET",
  PUT = "PUT",
  DELETE = "DELETE",
}


const SellerApi = {
  GetASellerUrl: (sellerId: string) => ({
    url: `/account/seller/${sellerId}`,
    method: ApiMethods.GET,
  }),

  UpdateASellerUrl: (sellerId: string) => ({
    url: `/account/seller/${sellerId}`,
    method: ApiMethods.PUT,
  }),
};

const AuthApi = {
  Login: {
    url: '/users/login',
    method: ApiMethods.POST
  },
  SignUp: {
    url: `/users/signUp`,
    method: ApiMethods.POST
  },
  ResetPassword: {
    url: `/users/resetPassword`,
    method: ApiMethods.POST

  },
  OtpRequest: {
    url: `/users/otp`,
    method: ApiMethods.POST
  }
}
const StoreApi = {
  CreateAStore: (sellerId: string) => ({
    url: `/seller/${sellerId}/store`,
    method: ApiMethods.POST

  }),
  GetAllStores: (sellerId: string) => ({
    url: `/seller/${sellerId}/store`,
    method: ApiMethods.GET

  }),
  GetAStore: (sellerId: string, storeId: string) => ({
    url: `/seller/${sellerId}/store/${storeId}`,
    method: ApiMethods.GET

  }),
  UpdateAStore: (sellerId: string, storeId: string) => ({
    url: `/seller/${sellerId}/store/${storeId}`,
    method: ApiMethods.PUT

  }),
  DeleteAStore: (sellerId: string, storeId: string) => ({
    url: `/seller/${sellerId}/store/${storeId}`,
    method: ApiMethods.DELETE

  }),
  StoreCount: (sellerId: string) => ({
    url: `/sellers/${sellerId}/stores/count`,
    method: ApiMethods.GET

  }),

};

const UserApi = {
  CreateAUser: (sellerId: string) => ({
    url: `/seller/${sellerId}/user`,
    method: ApiMethods.POST

  }),
  GetAllUsers: (sellerId: string) => ({
    url: `/seller/${sellerId}/user`,
    method: ApiMethods.GET

  }),
  GetAUser: (sellerId: string, userId: string) => ({
    url: `/seller/${sellerId}/user/${userId}`,
    method: ApiMethods.GET

  }),
  UpdateAUser: (sellerId: string, userId: string) => ({
    url: `/seller/${sellerId}/user/${userId}`,
    method: ApiMethods.PUT

  }),
  DeleteAUser: (sellerId: string, userId: string) => ({
    url: `/seller/${sellerId}/user/${userId}`,
    method: ApiMethods.DELETE

  }),
    UserCount:(sellerId:string) =>({
        url: `/seller/${sellerId}/users/count`,
        method: ApiMethods.GET
    })

};

const ValidationApi = {
  CreateValidationEntry: (sellerId: string, userId: string) => ({
    url: `/seller/${sellerId}/validation/verification`,
    method: ApiMethods.POST
  }),
  CreateOtpRequstForValidation: (sellerId: string, userId: string) => ({
    url: `/seller/${sellerId}/validation/otp`,
    method: ApiMethods.POST
  }),
  DeleteValidationEntry: (sellerId: string, userId: string, validationKey: string) => ({
    url: `/seller/${sellerId}/user/${userId}/validation/${validationKey}`,
    method: ApiMethods.DELETE
  }),

}

const IntegrationApi = {
  CreateIntegrationEntry: (sellerId: string) => ({
    url: `/seller/${sellerId}/integrations/component`,
    method: ApiMethods.POST
  }),
  RunAnIntegrationEntry: (sellerId: string, componentId: string) => ({
    url: `/seller/${sellerId}/integrations/component/${componentId}/run`,
    method: ApiMethods.POST
  }),
  UpdateIntegrationEntry: (sellerId: string, componentId: string) => ({
    url: `/seller/${sellerId}/integrations/component/${componentId}`,
    method: ApiMethods.PUT
  }),
  GetAllIntegrations: (sellerId: string) => ({
    url: `/seller/${sellerId}/integrations/component`,
    method: ApiMethods.GET
  }),
  GetAIntegration: (sellerId: string, componentId: string) => ({
    url: `/seller/${sellerId}/integrations/component/${componentId}`,
    method: ApiMethods.GET
  }),
  DeleteAIntegration: (sellerId: string, componentId: string) => ({
    url: `/seller/${sellerId}/integrations/component/${componentId}`,
    method: ApiMethods.DELETE
  }),

}

const CatalogueApi = {
  CreateCatalogue: (sellerId: string) => ({
    url: `/seller/${sellerId}/inventory/catalogue`,
    method: ApiMethods.POST

  }),
  GetAllCatalogues: (sellerId: string) => (
    {
      url: `/seller/${sellerId}/inventory/catalogue`,
      method: ApiMethods.GET
    }
  ),
  GetACatalogue: (sellerId: string, catalogueId: string) => (
    {
      url: `/seller/${sellerId}/inventory/catalogue/${catalogueId}`,
      method: ApiMethods.GET
    }
  ),
  SearchCatalogues: (sellerId: string) => (
    {
      url: `/seller/${sellerId}/search/catalogue/`,
      method: ApiMethods.GET
    }
  ),
  UpdateACatalogue: (sellerId: string, catalogueId: string) => (
    {
      url: `/seller/${sellerId}/inventory/catalogue/${catalogueId}`,
      method: ApiMethods.PUT
    }
  ),
  DeleteACatalogue: (sellerId: string, catalogueId: string) => (
    {
      url: `/seller/${sellerId}/inventory/catalogue/${catalogueId}`,
      method: ApiMethods.DELETE
    }
  ),
  CatalogueCount: (sellerId: string) => (
    {
      url: `/sellers/${sellerId}/inventory/catalogues/count`,
      method: ApiMethods.GET
    }
  ),
}

const OrderApi = {
  GetAllOrder: (sellerId: string) => ({
    url: `/seller/${sellerId}/order`,
    method: ApiMethods.GET

  }),

  GetAOrder: (sellerId: string, orderId: string) => ({
    url: `/seller/${sellerId}/order/${orderId}`,
    method: ApiMethods.GET

  }),
  UpdateAOrder: (sellerId: string, orderId: string) => ({
    url: `/seller/${sellerId}/order/${orderId}`,
    method: ApiMethods.PUT

  }),
  CreateInvoice: (sellerId: string, storeId: string) => ({
    url: `/seller/${sellerId}/store/${storeId}/orderinvoice`,
    method: ApiMethods.POST
  }),
  OrderCount:(sellerId:string) =>({
     url: `/seller/${sellerId}/orders/count`,
     method: ApiMethods.GET
    }),

}
const FileApi = {
  UploadAFile: (sellerId: string) => ({
    url: `/seller/${sellerId}/data/files/`,
    method: ApiMethods.POST
  }),
  GetAFile: (sellerId: string, fileUrl?: string) => ({
    url: `/seller/${sellerId}/data/files/get/${fileUrl}`,
    method: ApiMethods.GET
  })

}

const CouponBookletApi = {
  CreateACouponBooklet: (sellerId: string) => ({
    url: `/seller/${sellerId}/coupon-booklet`,
    method: ApiMethods.POST

  }),
  GetAllCouponBooklet: (sellerId: string) => ({
    url: `/seller/${sellerId}/coupon-booklet`,
    method: ApiMethods.GET

  }),
  GetACouponBooklet: (sellerId: string, couponBookletId: string) => ({
    url: `/seller/${sellerId}/coupon-booklet/${couponBookletId}`,
    method: ApiMethods.GET

  }),
  DeleteACouponBooklet: (sellerId: string, couponBookletId: string) => ({
    url: `/seller/${sellerId}/coupon-booklet/${couponBookletId}`,
    method: ApiMethods.DELETE
  }),
  UpdateACouponBooklet: (sellerId: string, couponBookletId: string,) => ({
    url: `/seller/${sellerId}/coupon-booklet/${couponBookletId}`,
    method: ApiMethods.PUT
  }),
  CouponCount: (sellerId: string) => ({
    url: `/sellers/${sellerId}/coupon-booklets/count`,
    method: ApiMethods.GET
  }),
}


const CommodityApi = {
  CreateACommodity: (sellerId: string) => ({
    url: `/seller/${sellerId}/inventory/commodity`,
    method: ApiMethods.POST

  }),
  GetAllCommodities: (sellerId: string) => (
    {
      url: `/seller/${sellerId}/inventory/commodity`,
      method: ApiMethods.GET
    }
  ),
  GetACommodity: (sellerId: string, commodityId: string) => (
    {
      url: `/seller/${sellerId}/inventory/commodity/${commodityId}`,
      method: ApiMethods.GET
    }
  ),
  SearchCommodities: (sellerId: string) => (
    {
      url: `/seller/${sellerId}/search/commodity`,
      method: ApiMethods.GET
    }
  ),

  UpdateACommodity: (sellerId: string, commodityId: string) => (
    {
      url: `/seller/${sellerId}/inventory/commodity/${commodityId}`,
      method: ApiMethods.PUT
    }
  ),
  DeleteACommodity: (sellerId: string, commodityId: string) => (
    {
      url: `/seller/${sellerId}/inventory/commodity/${commodityId}`,
      method: ApiMethods.DELETE
    }
  ),
  CommodityCount: (sellerId: string) => (
    {
      url: `/sellers/${sellerId}/inventory/commodities/count`,
      method: ApiMethods.GET
    }
  )
}
const StoreTemplateApi = {
  CreateAStoreTemplateaganistSeller: (sellerId: string) => ({
    url: `/seller/${sellerId}/layout`,
    method: ApiMethods.POST

  }),
  CreateDomainSeller: (sellerId: string) => ({
    url: `/seller/${sellerId}/domain`,
    method: ApiMethods.POST

  }),
  UpdateDomainSeller: (sellerId: string) => ({
    url: `/seller/${sellerId}/domain/domainUrl`,
    method: ApiMethods.PUT

  }),
  GetDomainSeller: (sellerId: string) => ({
    url: `/seller/${sellerId}/domain/domainUrl`,
    method: ApiMethods.GET

  }),
  GetStoreTemplateaganistStore: (sellerId: string, storeId: string) => (
    {
      url: `/seller/${sellerId}/store/${storeId}/layout`,
      method: ApiMethods.GET
    }
  ),
  GetStoreTemplateaganistSeller: (sellerId: string) => (
    {
      url: `/seller/${sellerId}/layout`,
      method: ApiMethods.GET
    }
  ),
  CreateAStoreTemplateaganistStore: (sellerId: string, storeId: string) => (
    {
      url: `/seller/${sellerId}/store/${storeId}/layout`,
      method: ApiMethods.POST
    }
  ),

  UpdateStoreTemplateAganistStore: (sellerId: string, storeId: string) => (
    {
      url: `/seller/${sellerId}/store/${storeId}/layout`,
      method: ApiMethods.PUT
    }
  ),
  UpdateStoreTemplateAganistSeller: (sellerId: string) => (
    {
      url: `/seller/${sellerId}/layout`,
      method: ApiMethods.PUT
    }
  ),
}

const chatApi = {

  getGroups: {
    url: '/api/v1/Chat/Groups',
    method: ApiMethods.GET
  },
  getUsersInGroup: (groupId: string) => (
    {
      url: `/api/v1/Chat/Groups/${groupId}/users`,
      method: ApiMethods.GET
    }
  ),
  getChatHistory: {
    url: '/api/v1/Chat/GetMessageLogs',
    method: ApiMethods.POST
  }
}


const reportApi = {
  getReports: (sellerId, reportId) => (
    {
      url: `/seller/${sellerId}/reports/${reportId}`,
      method: ApiMethods.GET
    }
  ),
  getReportsByStore: (sellerId, storeId, reportId) => (
    {
      url: `/seller/${sellerId}/store/${storeId}/reports/${reportId}`,
      method: ApiMethods.GET
    }
  )
}


const LicenseApi = {
  getLicense: (sellerId) => ({
    url: `/seller/${sellerId}/devices`,
    method: ApiMethods.GET
  }),
  removeDeviceLicense: (sellerId, deviceId) => ({
    url: `/seller/${sellerId}/devices/${deviceId}`,
    method: ApiMethods.DELETE
  }),
  disconnectDevice: (sellerId, deviceId) => ({
    url: `/seller/${sellerId}/devices/${deviceId}/disconnect`,
    method: ApiMethods.DELETE
  })
}

const CustomerProfileApi = {
  getAllCustomerProfile: (sellerId) => ({
    url: `/seller/${sellerId}/customer`,
    method: ApiMethods.GET
  }),
  getCustomerProfile: (sellerId, customerId) => ({
    url: `/seller/${sellerId}/customer/${customerId}`,
    method: ApiMethods.GET
  }),
  removeCustomerProfile: (sellerId, customerId) => ({
    url: `/seller/${sellerId}/customer/${customerId}`,
    method: ApiMethods.DELETE
  }),
  CreateCustomerProfile: (sellerId) => ({
    url: `/seller/${sellerId}/customer`,
    method: ApiMethods.POST
  }),
  updateCustomerProfile: (sellerId, customerId) => ({
    url: `seller/${sellerId}/customer/${customerId}`,
    method: ApiMethods.PUT
  }),
  MessageSendingApi:(sellerId) =>({
    url:`seller/${sellerId}/chats/whatsapp/text`,
    method:ApiMethods.POST
  }),
  CustomerCount:(sellerId) =>({
    url: `sellers/${sellerId}/customers/count`,
    method: ApiMethods.GET
  })
}

const messageTemplateApi = {
  getAllMessageTemplate: (sellerId) => ({
    url: `/seller/${sellerId}/templates`,
    method: ApiMethods.GET
  }),
  createMessageTemplate: (sellerId) => ({
    url: `/seller/${sellerId}/templates`,
    method: ApiMethods.POST
  }),
  updateMessageTemplate: (sellerId, templateId) => ({
    url: `/seller/${sellerId}/templates/${templateId}`,
    method: ApiMethods.PUT
  }),
  removeMessageTemplate: (sellerId, templateId) => ({
    url: `/seller/${sellerId}/templates/${templateId}`,
    method: ApiMethods.DELETE
  })
}

const CsvFile = {
  downloadCsvFile: (fileUrl) => ({
    url: fileUrl,
    method: ApiMethods.GET,
    responseType: 'blob'
  })
}

const analyticsApi = {
    gauageChart: (sellerId: string) => ({
        url: `sellers/${sellerId}/analytics/metrics/guage`,
        method: ApiMethods.POST
    }),
    histogramChart: (sellerId: string) => ({
        url: `sellers/${sellerId}/analytics/metrics/histogram`,
        method: ApiMethods.POST
    }),
    counter:(sellerId:string) =>({
        url:`sellers/${sellerId}/analytics/metrics/counter`,
        method:ApiMethods.POST
    }),
    point:(sellerId:string) =>({
        url:`sellers/${sellerId}/analytics/metrics/points`,
        method:ApiMethods.POST
    }),
    templateDownloadUrl:(fileUrl:string) =>({
        url: fileUrl,
        method: ApiMethods.GET,
        
    })
}

export {
  ApiMethods,
  SellerApi,
  AuthApi,
  StoreApi,
  UserApi,
  ValidationApi,
  CatalogueApi,
  CommodityApi,
  OrderApi,
  CouponBookletApi,
  IntegrationApi,
  FileApi,
  chatApi,
  StoreTemplateApi,
  reportApi,
  LicenseApi,
  CustomerProfileApi,
  CsvFile,
  messageTemplateApi,
  analyticsApi
};
