const inputFieldConstants = {
  name: {
    id: "userName",
    label: "Name",
    placeHolder: "Name",
    componentName: "Name",
  },

  email: {
    id: "email",
    label: "Email",
    placeHolder: "Email",
    componentName: "Email",
  },

  phoneNumber: {
    id: "phoneNumber",
    label: "Phone Number",
    placeHolder: "Phone Number",
    componentName: "Phone Number",
  },

  permissions: {
    id: "permissions",
    label: "Permissions",
    placeHolder: "",
    componentName: "Permissions",
  },

  address: {
    id: "address",
    label: "Address",
    placeHolder: "user Address",
    componentName: "Address",
  },

  pinCode: {
    id: "pincode",
    label: "Pincode",
    placeHolder: "Pincode",
    componentName: "Pincode",
  },

  city: {
    id: "city",
    label: "City",
    placeHolder: "City",
    componentName: "City",
  },

  district: {
    id: "district",
    label: "District",
    placeHolder: "District",
    componentName: "District",
  },


  state: {
    id: "state",
    label: "State",
    placeHolder: "State",
    componentName: "State",
  },

  country: {
    id: "country",
    label: "Country",
    placeHolder: "Country",
    componentName: "Country",
  }

};

export { inputFieldConstants };
