import { IIntegrationDataModel,IIntegrationActiveViewModel} from "@/Interfaces/DataModel/IntegrationDataModel";
import ApiService from "../Common/ApiService";
import { IntegrationApi } from "../Common/ApiRoutes";
import { IResponseDataModel } from "@/Interfaces/DataModel/ResponseDataModel";

function CreateAnIntegrationEntry(sellerId:string,data:any):Promise<IResponseDataModel<IIntegrationDataModel>>
{
    const { url, method }: { url: string, method: string } = IntegrationApi.CreateIntegrationEntry(sellerId);
    return ApiService.SendRequest(url, method, { data })
}
function UpdateAnIntegrationEntry(sellerId:string,componentId:string,data:any):Promise<IResponseDataModel<IIntegrationDataModel>>
{
    const { url, method }: { url: string, method: string } = IntegrationApi.UpdateIntegrationEntry(sellerId,componentId);
    return ApiService.SendRequest(url, method, { data })
}
function RunIntegrationEntry(sellerId:string,componentId:string,data:IIntegrationDataModel):Promise<IResponseDataModel<IIntegrationDataModel>>
{
    const { url, method }: { url: string, method: string } = IntegrationApi.RunAnIntegrationEntry(sellerId,componentId);
    return ApiService.SendRequest(url, method, { data })
}
function GetAllIntegrations(sellerId:string):Promise<IResponseDataModel<IIntegrationDataModel[]>>
{
    const { url, method }: { url: string, method: string } = IntegrationApi.GetAllIntegrations(sellerId);
    return ApiService.SendRequest(url, method)
}
function GetAIntegration(sellerId:string,componentId:string,data:IIntegrationDataModel):Promise<IResponseDataModel<IIntegrationDataModel>>
{
    const { url, method }: { url: string, method: string } = IntegrationApi.GetAIntegration(sellerId,componentId);
    return ApiService.SendRequest(url, method)
}
function DeleteAIntegrationEntry(sellerId:string,componentId:string):Promise<IResponseDataModel<IIntegrationDataModel>>
{
    const { url, method }: { url: string, method: string } = IntegrationApi.DeleteAIntegration(sellerId,componentId);
    return ApiService.SendRequest(url, method)
}
export{
    CreateAnIntegrationEntry,
    GetAIntegration,
    GetAllIntegrations,
    DeleteAIntegrationEntry,
    UpdateAnIntegrationEntry,
    RunIntegrationEntry
}