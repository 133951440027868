import { IBroadCastMessageViewModel } from "@/Interfaces/DataModel/CustomerDataModel"

export function ToEmptyWhatsAppBroadcastActiveViewModel(regionId,sellerId,  userId):IBroadCastMessageViewModel {
    const emptyModel = {
        regionId,
        sellerId,
        storeId: "",
        userId,
        templateId: "",
        messageBody: "",
        broadcastName:"",
        recipients: [
            ""
        ]
    }
    return emptyModel
}