import { TYPE as IToastType, useToast } from "vue-toastification";
const toast = useToast();
function CreateNotification(key: string) {
    toast.success(`${key} has been created successfully`)
}
function UpdateNotification(key: string) {
    toast.success(`${key} has been updated successfully`)
}
function DeleteNotification(key: string) {
    toast.success(`${key} has been deleted successfully`)
}
function AuthSuccesNotification(key: string) {
    toast.success(`${key} Was Successful`)
}
function OtpSuccesNotification() {
    toast.success("Otp has been sent your number successfully");
}
function ResetPasswordNotification() {
    toast.success("Password has been changed");
}
function underDevelopmentNotification() {
    toast.info("This feature is not implemented yet. Stay tuned for future updates!")
  } 

  function sendedBroadCastMessage() {
    toast.info("Intialized broadCast Message sending")
  } 
function CustomNotification(message: string, toastType: string) {
    toast[toastType](message)
}

export {
    CreateNotification,
    UpdateNotification,
    DeleteNotification,
    AuthSuccesNotification,
    OtpSuccesNotification,
    ResetPasswordNotification,
    CustomNotification,
    underDevelopmentNotification,
    sendedBroadCastMessage

}