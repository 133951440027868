import { createRouter, createWebHistory, NavigationGuardWithThis, RouteLocation } from "vue-router";
import Seller from "@/views/pages/profile/Seller.vue"
import User from "@/views/pages/profile/User.vue"
import Store from "@/views/pages/profile/Store.vue"
import OrdersBoard from "@/views/pages/order/OrderBoard.vue"
import PastOrder from "@/views/pages/order/PastOrder.vue"
import InventoryCommodites from "@/views/pages/inventory/Commodity.vue"
import InventoryCatalogues from "@/views/pages/inventory/Catalogue.vue"
import Coupons from "@/views/pages/inventory/Coupon.vue"
import Home from "@/views/pages/auth/Home.vue"
import Login from "@/views/pages/auth/Login.vue"
import SignUp from "@/views/pages/auth/SignUp.vue"
import ForgotPassword from "@/views/pages/auth/ForgetPassword.vue"
import Chat from "@/views/pages/chat.vue"
 import Reports from "@/views/pages/reports/Analytics.vue"
import NotFound from "@/views/pages/auth/NotFound.vue"
import Integration from '@/views/pages/Integration/IntegrationPage.vue'
import ReportIssue from '@/views/pages/help/ReportIssue.vue'
import InventoryCollections from "@/views/pages/inventory/PublishedCatalogueList.vue"
import StoreDevices from "@/views/pages/accessories/Devices.vue"
import StoreLayoutEditor from '@/views/pages/profile/StoreLayoutEditor.vue'
import Pricing from "@/views/pages/Pricing.vue"
import CustomerProfile from "@/views/pages/customerReleationshipManagement/CustomerProfile.vue"
import BroadcastMessageTemplates from "@/views/pages/communication/BroadcastMessageTemplates.vue"
import store from "@/store";
import { isTokenExpired } from "@/service/tokenService";
import Dashboard from "@/views/pages/dashboard/OverView.vue"

const routes = [{
    path: "/",
    name: "Landing",
    component: CustomerProfile,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/",
    name: "Default",
    component: CustomerProfile,
    meta: {
      requiresAuth: true,
    },
  },
    {
      path: "/account/seller",
      name: "Seller",
      component: Seller,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/account/user",
      name: "User",
      component: User,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/account/store",
      name: "Store",
      component: Store,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/account/StoreLayoutEditor",
      name: "StoreLayoutEditor",
      component: StoreLayoutEditor,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/orders/dashboard",
      name: "OrderDashboard",
      component: OrdersBoard,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/orders/history",
      name: "PastOrder",
      component: PastOrder,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/inventory/commodities",
      name: "Commodities",
      component: InventoryCommodites,
      meta: { requiresAuth: true },
    },
    {
      path: "/inventory/catalogues",
      name: "Catalogues",
      component: InventoryCatalogues,
      meta: { requiresAuth: true },
    },
    {
      path: "/inventory/Coupons",
      name: "Coupons",
      component: Coupons,
      meta: { requiresAuth: true },
    },
    {
      path: "/inventory/activeProductsCollections",
      name: "Collections",
      component: InventoryCollections,
      meta: { requiresAuth: true },
    },
    {
      path: "/accessories/assistant/salesassistant",
      name: "SalesAssistant",
      component: StoreDevices,
      meta: { requiresAuth: true },
    },
    {
      path: "/CRM/customerProfile",
      name: "CustomerProfile",
      component: CustomerProfile,
      meta: { requiresAuth: true },
    },
    {
      path: "/Pricing",
      name: "Pricing",
      component: Pricing,
    },

    {
      path: "/home",
      name: "Home",
      component: Home,
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/register",
      name: "SignUp",
      component: SignUp,
      meta: {
        requiresAuth: false,
      },
    },

    {
      path: "/forgotpassword",
      name: "ForgotPassword",
      component: ForgotPassword,
      meta: {
        requiresAuth: false,
      },
    },

    {
      path: "/notfound",
      name: "NotFound",
      component: NotFound,
      meta: {
        requiresAuth: false,
      },
    },

    {
      path: "/integration",
      name: "Integration",
      component: Integration,
      meta: {
        requiresAuth: true,
      },
    },

    {
      path: "/chat",
      name: "Chat",
      component: Chat,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/Communication/BroadcastMessageTemplates",
      name: "BroadcastMessageTemplates",
      component: BroadcastMessageTemplates,
      meta: { requiresAuth: true },
    },

    {
      path: "/reports",
      name: "Reports",
      component: Reports,
      meta: {
        requiresAuth: true,
      },
    },
  
    {
      path: "/help/reportbug",
      name: "ReportIssue",
      component: ReportIssue,
      meta: {
        requiresAuth: true,
      },
    },
    {
        path: "/Dashboard/OverView",
        name: "DashboardOverView",
        component: Dashboard,
        meta: {
            requiresAuth: true,
        },
    }

]




const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active",
});



router.beforeEach(userRouteValidation)


function userRouteValidation(navigateTo: any) {

  const token = store.getters['userCred/getAccessToken']
  
  const isAuthenticated =  !isTokenExpired(token)

  if(navigateTo.matched.length === 0) {
    return { name: 'NotFound' }
  }
  else if((!navigateTo.meta.requiresAuth || isAuthenticated)) {
    return true
  } else {
    ['resetState', 'userCred/resetState', 'catelogue/resetState', 'commodity/resetState', 'stores/resetState'].map(mutation => { store.commit(mutation) })
    return { name: 'Login' }
  }


}



export default router;