import { config } from "@/config/apiConfig";
import axios from 'axios'
import geoHash from 'ngeohash'
import { capitalizeFirstLetter } from "./Common/utilityService";

export async function getLocationDetailsFromCoordinates(coordinates) {
    const params = {
        format: 'jsonv2',
        lat: coordinates.lat,
        lon: coordinates.lng
    }
    try {
        const { data } = await axios.get(config.OpenStreetMapReverseAddressLookUp, { params })

        return {
            address: data.display_name,
            geoCode: GetGeoCodeFromCoordinates(data.lat, data.lon),
            city: data.address.village || data.address.amenity || data.address.county,
            district: data.address.state_district,
            pinCode: data.address.postcode,
            state: data.address.state,
            country: data.address.country
        }
    }
    catch (err) {
        console.log(err)
    }

}

export function GetGeoCodeFromCoordinates(latitude: string, longitude: string) {
    return geoHash.encode(latitude, longitude)
}

export function GetCoordinatesFromGeoCode(geoCode: string) {
    return geoHash.decode(geoCode)
}

export async function getAddressInfo(pinCode: string) {

    try {
        const { data } = await axios.get(`${config.OpenPinCodeService}/${pinCode}`)
        if (data[0] && data[0].PostOffice && data[0].PostOffice[0]) {
            const addressDataModel = data[0].PostOffice[0]
            return {
                city: capitalizeFirstLetter(addressDataModel.Block),
                district: capitalizeFirstLetter(addressDataModel.District),
                pinCode: capitalizeFirstLetter(addressDataModel.Pincode),
                state: capitalizeFirstLetter(addressDataModel.State),
                country: capitalizeFirstLetter(addressDataModel.Country)
            }
        }
    } catch (err) {
        console.log(err)
    }

}