const messageTemplateListTableHeading = [
    {
      field: "Name",
      editable: false,
      rowDrag: true,
      cellRenderer: (params: any) =>
        params.data?.templateName
          ? `<p class="text-xs mt-3 font-weight-bolder text-wrap lh-lg">${params.data.templateName.slice(0, 500)}</p>`
          : "Template Name",
      minWidth: 200,
      maxWidth: 800,
    //   headerCheckboxSelection: true,
    //   checkboxSelection: true,
    //   cellRendererParams: {
    //     checkboxSelection: function (params: any) {
    //       return {
    //         suppressMenu: true,
    //         width: 60, // Adjust width if needed
    //         height: 6, // Adjust height if needed
    //         // Add more properties as needed
    //       };
    //     },
    // },
  },
    {
      field: "Category",
      editable: false,
      rowDrag: true,
      cellRenderer: (params: any) =>
        params.data?.category
          ? `<p class="text-xs mt-3 font-weight-bolder text-wrap lh-lg">${params.data.category.slice(0, 500)}</p>`
          : "Category",
      minWidth: 200,
      maxWidth: 800,
    },
    {
      field: "Status",
      editable: false,
      rowDrag: true,
      cellRenderer: (params: any) =>
        params.data?.status
          ? `<p class="text-xs mt-3 font-weight-bolder text-wrap lh-lg">${params.data.status.slice(0, 500)}</p>`
          : "Status",
      minWidth: 200,
      maxWidth: 800,
    },
    {
        field: "Last Updated",
        editable: false,
        rowDrag: true,
        cellRenderer: (params: any) =>
          params.data?.lastUpdated
            ? `<p class="text-xs mt-3 font-weight-bolder text-wrap lh-lg">${params.data.lastUpdated.slice(0, 500)}</p>`
            : "Last Updated",
        minWidth: 200,
        maxWidth: 800,
      },
    {
      field: "Open",
      lockPosition: "right",
      cellRenderer: (params: any) => `<i class="text-lg fas fa-arrow-alt-circle-right"></i>`, // Ensure correct Font Awesome icon class
      minWidth: 200,
      maxWidth: 800,
    },
  ];
  

  export {messageTemplateListTableHeading}