<template>
  <div class="container-fluid">
   
    <div class="card row mb-3 mt-6">
      <div class="col-12 d-flex justify-content-end">
        <ArgonButton
          :variant="isDarkMode ? 'outline' : 'outline'"
          :color="isDarkMode ? 'white' : 'primary'"
          @click.prevent="updateDataLoad()"
          class="mb-3 mt-3"
        >
          Load Sample Data
        </ArgonButton>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-12 mb-4">
        <div class="card">
          <div class="card-header">
            <h6 class="mb-0 text-xs" style="color: #7c7d86">
             Existing Customer
              <span class="tag" style="margin-left: 3px">PRO ✨</span>
            </h6>
          </div>
          <div class="card-body">
            <div class="chart" v-if="shareDatasetConfig">
              <shareDataset :shareDatasetConfig="shareDatasetConfig"></shareDataset>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 mb-4">
        <div class="card">
          <div class="card-header">
            <h6 class="mb-0 text-xs" style="color: #7c7d86">
             New Customer
              <span class="tag" style="margin-left: 3px">PRO ✨</span>
            </h6>
          </div>
          <div class="card-body">
            <div class="chart">
              <areaPieces :areaPiecesDataSetChartData="areaPiecesConfig"></areaPieces>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 d-flex">
        <h6 class="mb-4 text-md mx-3">
          Customer Perspective
          <span class="tag" style="margin-left: 3px">PRO ✨</span>
        </h6>
      </div>
      <div class="col-lg-6 col-md-12 mb-4">
        <div class="card">
          <div class="card-header">
            <h6 class="mb-0 text-xs" style="color: #7c7d86">
             Customer Trends
              <span class="tag" style="margin-left: 3px">PRO ✨</span>
            </h6>
          </div>
          <div class="card-body">
            <div class="chart">
              <areaPieces
                :areaPiecesDataSetChartData="multipleRevenueContentConfig"
              ></areaPieces>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, defineProps, defineEmits, watch } from "vue";
import * as echarts from "echarts";
import shareDataset from "@/components/custom/dashboard/shareDataset.vue";
import areaPieces from "@/components/custom/dashboard/areaPieces.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import responsiveTitleCards from "@/components/custom/dashboard/responsiveTitleCards.vue";
import responsiveRowCards from "@/components/custom/dashboard/responsiveRowCards.vue";
const emit = defineEmits(["loadsampleData"]);

const chartContainer = ref(null);
const myChart = ref(null);
const props = defineProps({

  shareDatasetConfig: {
    type: Object,
    required: true,
  },
  areaPiecesConfig: {
    type: Object,
    required: true,
  },
  responsiveTitleCardConfig: {
    type: Object,
    required: true,
  },
  responsiveRowCardConfig: {
    type: Object,
    required: true,
  },
  multipleRevenueContentConfig: {
    type: Object,
    required: true,
  },
});
onMounted(() => {

  console.log(props.responsiveRowCardConfig, "props.shareDataset");
});
const updateDataLoad = () => {
  emit("loadsampleData");
};

watch(
  () => props.option,
  (newOption) => {
    if (myChart.value) {
      console.log(props.option, "option");
      myChart.value.setOption(newOption, true);
    }
  },
  { deep: true } // Watch deeply if option is an object
);
</script>
<style scoped>
.tag {
  /* position: absolute; */
  /* top: 4px; */
  background-color: #144888;
  color: white;
  padding: 3px 8px;
  border-radius: 10px;
  font-size: 12px;
  font-weight: bold;
}
</style>
