
import AnalyticsCard from "./AnalyticsCard.vue"
import TrafficChart from "./TrafficChart.vue";
export default {
  name: "App",
  components: {
    AnalyticsCard,
    TrafficChart
  },
}
