import { IFileDataModel } from "@/Interfaces/DataModel/FileDataModel";
import ApiService from "../Common/ApiService";
import { FileApi } from "../Common/ApiRoutes";
import { IResponseDataModel } from "@/Interfaces/DataModel/ResponseDataModel";

function UploadAFile(sellerId: string, data: any ): Promise<IResponseDataModel<IFileDataModel>> {
    const { url, method }: { url: string, method: string } = FileApi.UploadAFile(sellerId)
    return ApiService.SendRequest(url, method, { data })
}

function GetAFile(sellerId: string, fileurL: string): Promise<string> {
    const { url, method }: { url: string, method: string } = FileApi.GetAFile(sellerId, fileurL)
    return ApiService.SendRequest(url, method, { responseType: "blob" })
}



export {
    UploadAFile,
    GetAFile
}
