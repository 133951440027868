
const mutations = {
   setUserId: 'SET_USER_ID',
   setSellerId: 'SET_SELLER_ID',
   setAuth: 'SET_AUTH'
}

const actions = {
   setUserId: 'SET_USER_ID',
   setSellerId: 'SET_SELLER_ID',
   setAuth: 'SET_AUTH'
}


const nameSpace = 'userCred'

function getActionName(actiontype: string): string {
   return `${nameSpace}/${actiontype}`
}

export { actions as actionsConstant, mutations as mutationsConstant, nameSpace, getActionName }

export default actions