function defaultState() {
    return {
      iscustomerProfileSelected: false,
    }
  }
  
  export default {
  
    namespaced: true,
  
    state: defaultState(),
  
    mutations: {
  
      updateIsCustomerProfileSelected(state: any, payload: boolean) {
        state.iscustomerProfileSelected = payload
      },
  
      resetState(state: any) {
        Object.assign(state, defaultState())      
  
      },
    },
  
    actions: {
      setIsCustomerProfileSelected(ctx: any, payload: boolean) {
        ctx.commit("updateIsCustomerProfileSelected", payload)
      }
  
    },
    getters: {
      getIsCustomerSelected: (state: any) => state.iscustomerProfileSelected,
    },
  }
  