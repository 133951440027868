import { HubConnectionBuilder, HubConnectionState, LogLevel } from "@microsoft/signalr"
import { logger } from "./LoggerService";


export class CommunicationService {
    private connection
    private retryConnectCount = 3
    
    constructor(url, token) {
       this.connection = new HubConnectionBuilder()
       .configureLogging(LogLevel.Debug)
       .withUrl(url, { accessTokenFactory: () => token })
       .withAutomaticReconnect()
       .build();
   }

    async start() {
        try {
            if(this.connection.state == HubConnectionState.Disconnected ) {
                await this.connection.start()
            }
        } 
        catch(err) {
            logger.error(err)
            if(this.retryConnectCount !== 0) {
                logger.info(`Retrying signalR Connection ${this.retryConnectCount}`)
                this.retryConnectCount--
                this.start()
            }
        }     
    } 

    async terminate() {
        try {
            await this.connection.stop()
        } 
        catch(err) {
            logger.error(err)
        }  
    }

    async invokeAMethod(methodName: string, data?: any) {
        try {
            if(this.connection.state == HubConnectionState.Connected) {
                await this.connection.invoke(methodName, data)
            }
        } 
        catch(err) {
            logger.error(err)
        } 
    }

    async registerAListener(methodName: string, callBack?: any) {
        try {
            if(this.connection.state == HubConnectionState.Connected) {
                await this.connection.on(methodName, callBack)
            }
        } 
        catch(err) {
            logger.error(err)
        }
    }
    getConnectionState() {
        return this.connection?.state
    }

}