import swal from "sweetalert2";
import type { SweetAlertResult } from "sweetalert2"

import { IValidationDataModel, IValidationViewModel } from "@/Interfaces/DataModel/LoginDataModel";
import { IUserActiveViewModel } from "@/Interfaces/DataModel/UserDataModel";
import { CreateAOtpRequestForValidation, CreateAValidationEntryRequest, DeleteValidationEntry } from "./apiService/ValidationSerice";
import { IResponseDataModel } from "@/Interfaces/DataModel/ResponseDataModel";

async function OTPHandler(userActiveViewModel: IUserActiveViewModel): Promise<boolean> {
  const { sellerId, userId, regionId, phoneNumber } = userActiveViewModel
  const validationViewModel: IValidationViewModel = getValidationViewModel(userId, phoneNumber, sellerId, regionId);

  CreateAOtpRequestForValidation(
    sellerId,
    userId,
    {
      email:userActiveViewModel.email,
      tagKey:phoneNumber
    }
  )
  const result = await ConfirmRecivedOTP(userId, sellerId, regionId, phoneNumber);
  return result.isConfirmed ? result.value?.isSuccess : false;
}

async function ConfirmRecivedOTP(userId: string, sellerId: string, regionId: string, phoneNumber: string, msg = ""): Promise<SweetAlertResult<any>> {
  return await swal.fire({
   title: "Phone Number Verification",
   text:
     msg === "" ? "Enter OTP received on the number" : "Invalid OTP entered",
   input: "text",
   showCancelButton: true,
   confirmButtonText: "Verify",
   showLoaderOnConfirm: true,
   preConfirm: (otp: string) => sendRequestWithOTP(userId, sellerId, regionId, phoneNumber, otp),
 });

}

async function sendRequestWithOTP(userId: string, sellerId: string, regionId: string, phoneNumber: string, otp: string): Promise<IResponseDataModel<IValidationDataModel> | void> {
  const validationViewModel: IValidationViewModel = getValidationViewModel(userId, phoneNumber, sellerId, regionId, otp);
  const resp = await CreateAValidationEntryRequest(
    sellerId,
    userId,
    validationViewModel
  )
    
  if(!resp.isSuccess) {
    // TODO: check status
    swal.showValidationMessage(resp.responseCode === 409 ? "Try different number" : "Invalid OTP")
  } else {
    return resp
  }
}


function getValidationViewModel(userId: string, tagKey: string, sellerId: string, regionId: string, otp = ""): IValidationViewModel {
    return {

      tagKey,
      otp,
      sellerId,
      regionId,
      validationStatus:""
    };
  }


function DeleteOldNumber(sellerId: string, userId: string, oldNumber: string, newNumber?: string) {
  const numbersAreNotSame = newNumber ? (oldNumber !== newNumber) : true 
  if (numbersAreNotSame) {
    DeleteValidationEntry(
      sellerId,
      userId,
      oldNumber
    );
  }
}

  export default { DeleteOldNumber, OTPHandler }