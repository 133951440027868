

import { customerDataTypes } from '@/config/dataConfig';
import store from '@/store'
import { computed } from "vue";

const isDarkMode = computed(() => store.state.argon.darkMode )

const dataTypes = Object.values(customerDataTypes).map(item => item.toString())



const switchSelector = (status: boolean) => {
  return status ? `<div class="form-check form-switch ps-0 mt-3"><input class="form-check-input ms-0" type="checkbox" checked="true"/></div>` :
    `<div class="form-check form-switch ps-0 mt-3"><input class="form-check-input ms-0" type="checkbox" /></div>`

}


const dataTypeSelectore = () => ({
  component: "agSelectCellEditor",
  params: {
    values: dataTypes,
  },
  popup: true
})

const commodityTableHaeding = [
 
  {
    field: "name",
    editable: true,
    sortable: true,
    rowDrag: true,
    filter: 'agTextColumnFilter',
    minWidth: 300
  },
  {
    field: "value",
    editable: true,
    sortable: true,
    filter: "agTextColumnFilter",
    minWidth: 300
  },
  // {
  //   field: "type",
  //   editable: true,
  //   sortable: true,
  //   cellEditorSelector: dataTypeSelectore,
  //   minWidth: 100,
  // },
  // {
  //   field: "searchable",
  //   editable: false,
  //   sortable: true,
  //   cellRenderer: (params: any) => switchSelector(params.data?.searchable),
  //   minWidth: 100,
  //   maxWidth: 250,

  // },
  // {
  //   field: "facet",
  //   editable: false,
  //   sortable: true,
  //   cellRenderer: (params: any) => switchSelector(params.data?.facet),
  //   minWidth: 100,
  //   maxWidth: 250
  // },
  // {
  //   field: "optional",
  //   editable: false,
  //   sortable: true,
  //   cellRenderer: (params: any) => switchSelector(params.data?.optional),
  //   minWidth: 100,
  //   maxWidth: 250
  // },
  {
    field: "Add",
    editable: false,
    cellRenderer: (params: any) => `<button class="btn btn mt-3"> <i class="fas fa-plus text-primary"></i> </button>`,
    lockPosition: 'right',
    minWidth: 80,
    maxWidth: 120
  },
  {
    field: "Remove",
    editable: false,
    cellRenderer: (params: any) => `<button class="btn mt-3"> <i class="fas fa-trash text-primary"> </i> </button>`,
    lockPosition: 'right',
    minWidth: 80,
    maxWidth: 120
  },
]


const integrationChannelListTableHeading = [
  {
    field: "Component Name",
    editable: false,
    rowDrag: true,
    cellRenderer: (params: any) =>
      params.data?.componentName
        ? `<p class="text-xs mt-3 font-weight-bolder text-wrap lh-lg">${params.data.componentName.slice(0, 500)}</p>`
        : "Component Name",
    minWidth: 200,
    maxWidth: 800,
    headerCheckboxSelection: true,
    checkboxSelection: true,
    cellRendererParams: {
      checkboxSelection: function (params: any) {
        return {
          suppressMenu: true,
          width: 60, // Adjust width if needed
          height: 6, // Adjust height if needed
          // Add more properties as needed
        };
      },
  },
},
  {
    field: "Integration Name ",
    editable: false,
    rowDrag: true,
    cellRenderer: (params: any) =>
      params.data?.integrationName
        ? `<p class="text-xs mt-3 font-weight-bolder text-wrap lh-lg">${params.data.integrationName.slice(0, 500)}</p>`
        : "Integration name",
    minWidth: 200,
    maxWidth: 800,
  },
  {
    field: "Open",
    lockPosition: "right",
    cellRenderer: (params: any) => `<i class="text-lg fas fa-arrow-alt-circle-right"></i>`, // Ensure correct Font Awesome icon class
    minWidth: 200,
    maxWidth: 800,
  },
];


const defaultColDef = {
  flex: 1,
  minWidth: 150,
  sortable: true,
  resizable: true,
};





export {
  commodityTableHaeding as AgGridHeaderConfig, defaultColDef, integrationChannelListTableHeading
}