
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from "chart.js";
import { Line as LineChartGenerator } from "vue-chartjs";
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
);

export default {
  name: "App",
  components: {
    LineChartGenerator,
  },
  props: {
    chartData: {
      type: Object,
      required: true,
      default: () => ({
        labels: ["January", "February", "March"],
        backgroundColor: "#53bfee",
        datasets: [{ data: [0, 0, 0] }],
      }),
    },
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
};
