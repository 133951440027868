import { uploadImage as uploadStoreLogo, setImgUrl } from "@/service/fileService"
async function convertDataModelToViewModel(dataModel, viewModel) {
    dataModel.sections.map(sectionItem => {
        if (sectionItem.sectionType === "bodySection") {
            viewModel.backgroundColor = sectionItem.attributes.bgColor
            viewModel.buttonColor = sectionItem.attributes.buttonColor
            viewModel.buttonText = sectionItem.attributes.buttonText
            viewModel.bannerImageFileUrl = sectionItem.attributes.banner
        }
        else if (sectionItem.sectionType === "storeNameBanner") {
            viewModel.backgroundImageFileUrl = sectionItem.attributes.backgroundImage
            viewModel.backgroundColor = sectionItem.attributes.bgColor
            viewModel.logImageFileUrl = sectionItem.attributes.logoShape
            viewModel.position = sectionItem.attributes.logoPosition
        }
        else if (sectionItem.sectionType === "footerSection") {
            console.log(sectionItem.sectionData.data.contactUs, "storetemplateactiveviewmodel")
            viewModel.instagram = sectionItem.sectionData.data.contactUs.socialLink.instagram
            viewModel.facebook = sectionItem.sectionData.data.contactUs.socialLink.facebook
            viewModel.twitter = sectionItem.sectionData.data.contactUs.socialLink.twitter
        }

    })
    const updatedDataModel= updateImagesDatamodel(dataModel)
    return updatedDataModel
} 
async function updateImagesDatamodel(dataModel){
// eslint-disable-next-line require-atomic-updates
dataModel.backgroundImage =await getImages(dataModel.backgroundImageFileUrl,dataModel.sellerId)
// eslint-disable-next-line require-atomic-updates
dataModel.bannerImage = await getImages(dataModel.bannerImageFileUrl,dataModel.sellerId)
// eslint-disable-next-line require-atomic-updates
dataModel.logo = await getImages(dataModel.logImageFileUrl,dataModel.sellerId)

return dataModel
}
function emptyCurrentLayout(viewModel){
    viewModel.backgroundColor = "#fafafa"
    viewModel.buttonColor = ""
    viewModel.buttonText = "BuyNow"
    viewModel.bannerImage = "https://pocketapp.in/img/blog-img/electronics1.jpg"
    viewModel.backgroundImage = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0bquih_e5sUIShI_zl0LuSuLZ3djWee0wu9FS3q7KyRu1UVXt-SyGLNgi-M9gLHEA4ao&usqp=CAU"
    viewModel.backgroundColor = ""
    viewModel.logo = "https://img.freepik.com/premium-vector/store-retail-logo-template_59362-82.jpg"
    viewModel.layoutPosition=""
    viewModel.position = "Center"
return viewModel
}

 function convertViewModelToDataModel(storeCustomizedTemplateDataModel,storetemplateactiveviewmodel){
    const sellerId = storetemplateactiveviewmodel.sellerId

    storeCustomizedTemplateDataModel.section.map( sectionItem =>{
        if(sectionItem.sectionType==="bodySection"){
          sectionItem.attributes.bgColor=storetemplateactiveviewmodel.bodyBackgroundColor
          sectionItem.attributes.buttonColor=storetemplateactiveviewmodel.buttonColor
          sectionItem.attributes.buttonText=storetemplateactiveviewmodel.buttonText
           sectionItem.attributes.banner=storetemplateactiveviewmodel.bannerImage
        }
         else if(sectionItem.sectionType==="storeNameBanner"){
          sectionItem.attributes.backgroundImage=storetemplateactiveviewmodel.backgroundImage
          sectionItem.attributes.bgColor=storetemplateactiveviewmodel.backgroundColor
          sectionItem.attributes.logoShape= storetemplateactiveviewmodel.logo
          sectionItem.attributes.logoPosition=storetemplateactiveviewmodel.position
        }
        else if(sectionItem.sectionType==="footerSection") {
          console.log(sectionItem.sectionData.data.contactUs,"storetemplateactiveviewmodel")
          sectionItem.sectionData.data.contactUs.socialLink.instagram=storetemplateactiveviewmodel.instagram
          sectionItem.sectionData.data.contactUs.socialLink.facebook=storetemplateactiveviewmodel.facebook
          sectionItem.sectionData.data.contactUs.socialLink.twitter=storetemplateactiveviewmodel.twitter
        }
      
      })
      return storeCustomizedTemplateDataModel
}
async function uploadImages(files,sellerId){
    console.log("fileuploading",files)
    const config = {
        uploadedFor: "storelogo",
        collectionType: "images",
        secureLevelCode: "notsecure",
        sellerId: sellerId,
        files,
      }
      const images:any = await uploadStoreLogo(config)
      console.log(images,"uploaded file")
      return images.length > 0 ? images[0].url : ""
}
async function getImages(files,sellerId){

    const image= await setImgUrl(files, sellerId)

      return image
      
}
export {convertDataModelToViewModel,emptyCurrentLayout,convertViewModelToDataModel,uploadImages}


