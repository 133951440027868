import { createStore, CommitOptions } from "vuex";
function defaultState() {
  return {
    addNewIntegrationChannelStatus: false, // TODO: commodityEditMode
    activeIntegrationChannel: null,
    isIntegrationChannelSelectd: false,
    activeIntegrationChannelTableData: null
  }
}

export default {

  namespaced: true,

  state: defaultState(),

  mutations: {

    toggleAddNewIntegrationChannel(state: any) {
      state.addNewIntegrationChannelStatus = !state.addNewIntegrationChannelStatus
    },

    updateIsIntegrationChannelSelected(state: any, payload: boolean) {
      state.isIntegrationChannelSelectd = payload
    },

    updateActiveIntegrationChannel(state: any, payload: any) {
      state.activeIntegrationChannelTableData = payload
    },
    resetState(state: any) {
      Object.assign(state, defaultState())      

    }
  },

  actions: {

    toggleAddNewIntegrationChannel(ctx: any) {
      ctx.commit("toggleAddNewIntegrationChannel");
    },
    setIsIntegrationChannelselected(ctx: any, payload: boolean) {
      ctx.commit("updateIsIntegrationChannelSelected", payload)
    },
    setActiveIntegrationChannel(ctx: any, payload: boolean) {
      ctx.commit("updateActiveIntegrationChannel", payload)
    },
    toggleAddNewChannel(ctx: any) {
      ctx.commit("toggleAddNewChannel");
    },
  },
  getters: {

    getAddNewIntegrationChanneltatus: (state: any) => state.addNewIntegrationChannelStatus,
    getIsIntegrationChannelSelected: (state: any) => state.isIntegrationChannelSelectd,
    getActiveIntegrationChannel: (state: any) => state.activeIntegrationChannelTableData,

  },
}
