import {

    IResponseDataModel
} from "@/Interfaces/DataModel/ResponseDataModel"
import {analyticsApi} from "../Common/ApiRoutes"
import ApiService from "../Common/ApiService"
import {
    IAnalyticsCounterViewDataModel ,
    IAnalyticsGaugeViewDataModel ,
    IAnalyticsHistogramViewDataModel ,
    IAnalyticsPointsViewDataModel , IAnalyticsRequestDataModel
}                 from "@/Interfaces/DataModel/AnalyticsDataModel"
import axios      from "axios";

function gauageChartGraphApi(data:IAnalyticsRequestDataModel, sellerId:string): Promise<IResponseDataModel<IAnalyticsGaugeViewDataModel>> {
    const {url, method}: { url: string, method: string } = analyticsApi.gauageChart(sellerId)
    return ApiService.SendRequest(url, method, {data})
}


function counterChartGraphApi(data:IAnalyticsRequestDataModel, sellerId:string): Promise<IResponseDataModel<IAnalyticsCounterViewDataModel>> {
    const {url, method}: { url: string, method: string } = analyticsApi.counter(sellerId)
    return ApiService.SendRequest(url, method, {data})
}

function histogramChartGraphApi(data:IAnalyticsRequestDataModel, sellerId:string): Promise<IResponseDataModel<IAnalyticsHistogramViewDataModel>> {
    const {url, method}: { url: string, method: string } = analyticsApi.histogramChart(sellerId)
    return ApiService.SendRequest(url, method, {data})
}

function pointsChartGraphApi(data:IAnalyticsRequestDataModel, sellerId:string): Promise<IResponseDataModel<IAnalyticsPointsViewDataModel>> {
    const {url, method}: { url: string, method: string } = analyticsApi.point(sellerId)
    return ApiService.SendRequest(url, method, {data})
}

async function templateDownload(fileUrl:string):Promise<any>{
    const {url,method} :{url:string,method:string}=analyticsApi.templateDownloadUrl(fileUrl);
    const response:any = await axios({ url, method });
    return response.data;
    
}



export {templateDownload,gauageChartGraphApi, counterChartGraphApi, histogramChartGraphApi, pointsChartGraphApi}