import { IStoreDataModel } from '@/Interfaces/DataModel/StoreDataModel';
import store from '@/store'
import { GetAllStore } from './apiService/StoreService';

type IStore = { storeName: string; storeId: string };

function modifyStoreData(storeData: IStoreDataModel): IStore {
    return {
        storeName: storeData.storeName,
        storeId: storeData.storeId
    }
}

function getStoreDataFromState() {
    return store.getters["stores/getStores"];
}

function setStoreDataToState(modifiedStoreData: IStore) {
    return store.dispatch("stores/setStores", modifiedStoreData);
}

export async function loadStoreList(sellerId: string) {

    let stores = getStoreDataFromState()

    if (stores.length === 0) {
        const resp = await GetAllStore(sellerId);
        if (resp.isSuccess && resp.result) {
            stores = resp.result.map(modifyStoreData);
            setStoreDataToState(stores)
        }
    }

    return stores


}

export {modifyStoreData,getStoreDataFromState}