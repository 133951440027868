import Logger from "pino"
import { useStore } from "vuex";
const store = useStore();


const logger = Logger({formatters: {
    log: (logObj) => {
        logObj.sellerId = store.getters["userCred/getSellerId"] 
        logObj.userId = store.getters["userCred/getUserId"]
        if(process.env.NODE_ENV === 'production') {
            return {...logObj, ...{ BuildId: process.env.BuildId }};
        } else {
            return logObj
        }
    },
}})

export { logger }
