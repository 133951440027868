import jwt from 'jsonwebtoken'
import type { JwtPayload } from 'jsonwebtoken'

function decryptToken(token: string): any {
    return jwt.decode(token)
}

function timeIsPast(time: number | undefined): boolean {
    return time ? Date.now() >= time * 1000 : true
}


function isTokenExpired(token: string | null): boolean {
    if (token) {
        const decodedToken: JwtPayload = decryptToken(token)
        return timeIsPast(decodedToken.exp)
    } else {
        return true
    }
}

export { isTokenExpired,decryptToken }