import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { getGroups } from "./apiService/ChatService";
import { IGroupDataModel } from "@/Interfaces/DataModel/ChatDataModel";
import { CommunicationService } from "./Common/communicationService";
import store from '@/store'
import { config } from "@/config/apiConfig";

const signalRConfig = config.signalR.chat

export const chatCommunicationService = new CommunicationService(signalRConfig.url, store.getters["userCred/getAccessToken"])

export async function getAndJoinUserGroups(sellerId: string, regionId: string) {
    const resp = await getGroups(sellerId, regionId)
    if (resp.result && resp.result[0]) {
        resp.result[0].userId = resp.result[0].groupId.split('+')[1]
        await ReJoinAllGroups(resp.result[0])
    }
}

export async function ReJoinAllGroups(groupData: IGroupDataModel) {
    await chatCommunicationService.invokeAMethod(signalRConfig.invoke.rejoinInGroups, groupData);
    return groupData;
}

export async function createNewGroup(groupData: IGroupDataModel) {
    await chatCommunicationService.invokeAMethod(signalRConfig.invoke.createAGroup, groupData);
    return groupData;
}

export async function JoinGroup(groupData: IGroupDataModel) {
    await chatCommunicationService.invokeAMethod(signalRConfig.invoke.joinAGroup, groupData);
    return groupData;
}


export async function initCommunications(sellerId: string, RegionId: string, routeName: string) {
    if (chatCommunicationService.getConnectionState() !== "Connected") {
      await chatCommunicationService.start();
      await getAndJoinUserGroups(sellerId, RegionId);
      await chatCommunicationService.registerAListener("OnMessage", (MessageDtaModel) => {
        if (MessageDtaModel.userType !== "store" && routeName !== "Chat") {
          const messageCount = store.getters["getUnreadMessageCount"] + 1;
          store.dispatch("updateUnreadMessageCount", messageCount);
        }
      });
    } else {
      setTimeout(() => {
        initCommunications(sellerId, RegionId, routeName)
      }, 500);
    }
  }