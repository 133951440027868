import { createStore, CommitOptions } from "vuex";

function defaultState() {
  return {
    addNewProductStatus: false,
    isCatelogueSelectd: false,
  }
}

export default {

  namespaced: true,

  state: defaultState(),

  mutations: {

    toggleAddNewProduct(state: any) {
      state.addNewProductStatus = !state.addNewProductStatus
    },

    closeAddProduct(state: any) {
      state.addNewProductStatus = false
    },

    updateIsCatelogueSelected(state: any, payload: boolean) {
      state.isCatelogueSelectd = payload
    },

    resetState(state: any) {
      Object.assign(state, defaultState())      

    }

  },

  actions: {

    toggleAddNewProduct(ctx: any) {
      ctx.commit("toggleAddNewProduct");
    },
    closeAddProduct(ctx: any) {
      ctx.commit("closeAddProduct")
    },
    setIsCatelogueselected(ctx: any, payload: boolean) {
      ctx.commit("updateIsCatelogueSelected", payload)
    }
  },
  getters: {

    getAddNewProductStatus: (state: any) => state.addNewProductStatus,
    getIsCatelogueSelected: (state: any) => state.isCatelogueSelectd,
  },
}
