import { ILicenseDataModel, ILicenseViewModel } from '../Interfaces/DataModel/LicenseDataModel';

export function licenseDataModelToViewModel(dataModel: ILicenseDataModel): ILicenseViewModel {
    return {
        regionId: dataModel.regionId,
        sellerId: dataModel.sellerId,
        storeId: dataModel.storeId,
        userId: dataModel.userId,
        deviceId: dataModel.deviceId,
        deviceName: dataModel.deviceName,
        deviceType: dataModel.deviceType,
        deviceStatus: dataModel.deviceStatus,
        deviceProperties: dataModel.deviceProperties
    }
}

export function licenseViewModelToDataModel(viewModel: ILicenseViewModel): ILicenseDataModel {
    return {
        regionId: viewModel.regionId,
        sellerId: viewModel.sellerId,
        storeId: viewModel.storeId,
        userId: viewModel.userId,
        deviceId: viewModel.deviceId,
        deviceName: viewModel.deviceName,
        deviceType: viewModel.deviceType,
        deviceStatus: viewModel.deviceStatus,
        deviceProperties: viewModel.deviceProperties
    }
}