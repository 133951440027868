interface IBoardHeadingConfig {
    name: string,
    id: string,
    rank: number,
}

enum stages {
    cancelled = 'Cancelled',
    new = 'New',
    accepted = 'Accepted',
    intransist = 'Intransist',
    closed = 'Closed'
}

const OrderStatus = ['Cancelled','New','Accepted','Intransist','Closed']
const BoardHeadingConfig: IBoardHeadingConfig[] = [
    {
        name: stages.cancelled,
        id: 'id_Cancelled',
        rank: 2
    },
    {
        name: stages.new,
        id: 'id_New',
        rank: 1
    },
    {
        name: stages.accepted,
        id: 'id_Accepted',
        rank:2
    },
    {
        name: stages.intransist,
        id: 'id_Intransist',
        rank:3
    },
    {
        name: stages.closed,
        id: 'id_Closed',
        rank:4
        
    }
]

function getStageRankByStageName (stageName: stages) {
    return BoardHeadingConfig.find(stage => stage.name == stageName).rank
}


export { BoardHeadingConfig, stages, getStageRankByStageName,OrderStatus }