import { ICatalogueDataModel } from "@/Interfaces/DataModel/CatalogueDataModel";
import { IResponseDataModel } from "@/Interfaces/DataModel/ResponseDataModel"
import { ISearchViewModel } from "@/Interfaces/DataModel/SearchDataModel";
import { CatalogueApi } from "../Common/ApiRoutes";
import ApiService from "../Common/ApiService";

function CreateACatalogue(sellerId: string, data: ICatalogueDataModel): Promise<IResponseDataModel<ICatalogueDataModel>> {
    const { url, method }: { url: string, method: string } = CatalogueApi.CreateCatalogue(sellerId);
    return ApiService.SendRequest(url, method, { data })
}
function GetAllCatalogue(sellerId: string, page: number = 0, mode?:string): Promise<IResponseDataModel<ICatalogueDataModel[]>> {
    const { url, method }: { url: string, method: string } = CatalogueApi.GetAllCatalogues(sellerId);
    return ApiService.SendRequest(url, method,{query:{page, status: mode || ""}})
}
function GetACatalogue(sellerId: string, catalogueId: string): Promise<IResponseDataModel<ICatalogueDataModel>> {
    const { url, method }: { url: string, method: string } = CatalogueApi.GetACatalogue(sellerId, catalogueId);
    return ApiService.SendRequest(url, method)
}
function SearchCatalogue(sellerId: string, searchKey: string,page): Promise<IResponseDataModel<ISearchViewModel<any>>> {
    const { url, method }: { url: string, method: string } = CatalogueApi.SearchCatalogues(sellerId);
    return ApiService.SendRequest(url, method, { query: { searchKey,page } })
}
function UpdateACatalogue(sellerId: string, catalogueId: string, data: ICatalogueDataModel): Promise<IResponseDataModel<ICatalogueDataModel>> {
    const { url, method }: { url: string, method: string } = CatalogueApi.UpdateACatalogue(sellerId, catalogueId);
    return ApiService.SendRequest(url, method, { data })
}
function DeleteACatalogue(sellerId: string, catalogueId: string): Promise<IResponseDataModel<ICatalogueDataModel>> {
    const { url, method }: { url: string, method: string } = CatalogueApi.DeleteACatalogue(sellerId, catalogueId);
    return ApiService.SendRequest(url, method)
}
function CatalogueCount(sellerId: string): Promise<IResponseDataModel<any>> {
    const { url, method }: { url: string, method: string } = CatalogueApi.CatalogueCount(sellerId);
    return ApiService.SendRequest(url, method)
}



export {
    CreateACatalogue,
    GetACatalogue,
    GetAllCatalogue,
    UpdateACatalogue,
    DeleteACatalogue,
    SearchCatalogue,
    CatalogueCount
}