import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { config } from "@/config/apiConfig";
import store from '@/store/'
import { merge } from "lodash";
const Qs = require('qs');
interface IApiRequestOptions {
    data?: any
    query?: any
    header?: any
    additionalParams?:any,
    responseType?: any
}

class ApiService {

    private axiosInstance: AxiosInstance

    constructor(baseURL: string) {

        this.axiosInstance = axios.create({
            baseURL,
            withCredentials: true,
            headers: {

                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": config.Origin,
                "Accept": "*/*",
                "Authorization": `Bearer ${store.getters['userCred/getAccessToken']}`

            }
        })
    }

    setToken(token: string) {
        this.axiosInstance.defaults.headers = merge(this.axiosInstance.defaults.headers, { ["Authorization"]: `Bearer ${token}` });
    }

    async SendRequest(url: string, method: string, requestOptions?: IApiRequestOptions) {
        try {
            const config: AxiosRequestConfig = {
                url,
                method,
                maxRedirects: 3,
                data: requestOptions?.data,
                params: requestOptions?.query,
                headers: requestOptions?.header,
                responseType: requestOptions?.responseType,
                paramsSerializer: params => Qs.stringify(params, {arrayFormat: 'repeat'})
            }
            const { data } = await this.axiosInstance(config);
            return data
        }
        catch (error: any) {
            return error.response && error.response.data ? error.response.data : errorResponseParse(error.response)
        }
    }

}

function errorResponseParse(err: any) {
    return {
        responseCode: err.status,
        isSuccess: false,
        stringErrorMessage: '',
    }
}

const ApiServiceInstance = new ApiService(config.BaseUrl)

export { ApiService }

export default ApiServiceInstance
