const ForgetPasswordFieldAttributes = {
  email: {
    id: "email",
    label: "Email",
    placeHolder: "Email",
    componentName: "Email",
  },

  otp: {
    id: "otp",
    label: "OTP",
    placeHolder: "Enter OTP",
    componentName: "OTP",
  },

  password: {
    id: "password",
    label: "Password",
    placeHolder: "New Password",
    componentName: "Password",
  },
};

const SignUpFieldAttributes = {
  name: {
    id: "name",
    label: "Name",
    placeHolder: "Name",
    componentName: "Name",
  },

    inviteCode: {
      id: "inviteCode",
      label: "inviteCode",
      placeHolder: "Invite Code",
      componentName: "inviteCode",
    },

  email: {
    id: "email",
    label: "Email",
    placeHolder: "Email",
    componentName: "Email",
  },

  phoneNumber: {
    id: "phoneNumber",
    label: "Phone Number",
    placeHolder: "Phone Number",
    componentName: "Phone Number",
  },

  password: {
    id: "password",
    label: "Password",
    placeHolder: "New Password",
    componentName: "Password",
  },

  confirmPassword: {
    id: "confirmPassword",
    label: "Confirm Password",
    placeHolder: "Enter Password Again",
    componentName: "Confirm Password",
  },

  otp: {
    id: "otp",
    label: "OTP",
    placeHolder: "Enter OTP",
    componentName: "OTP",
  }
};

const LoginFieldAttributes = {
  email: {
    id: "email",
    label: "Email",
    placeHolder: "Enter Email",
    componentName: "Email",
  },

  password: {
    id: "password",
    label: "Password",
    placeHolder: "New Password",
    componentName: "Password",
  }
};

export { ForgetPasswordFieldAttributes, SignUpFieldAttributes, LoginFieldAttributes };
