import { mergeObjects } from "@/service/Common/utilityService";

import { ISellerActiveViewModel, SellerDataModelKeys } from "@/Interfaces/DataModel/SellerDataModel";
import { IStoreActiveViewModel, StoreDataModelKeys } from "@/Interfaces/DataModel/StoreDataModel";
import { IUserActiveViewModel, UserDataModelKeys } from "@/Interfaces/DataModel/UserDataModel";

import { inputFieldConstants as SellerAttributes } from '@/views/configs/sellerProfileConfig'
import { inputFieldConstants as StoreAttributes } from '@/views/configs/storeProfileConfig'
import { inputFieldConstants as UserAttributes } from '@/views/configs/userProfileConfig'



function inputModelToSellerViewModel (sellerActiveViewModel: ISellerActiveViewModel, values: any): ISellerActiveViewModel {

    return mergeObjects(sellerActiveViewModel, values, {
        [SellerAttributes.name.componentName]: SellerDataModelKeys.sellerName.trim(),
        [SellerAttributes.address.componentName]: SellerDataModelKeys.address.trim(),
        [SellerAttributes.city.componentName]:SellerDataModelKeys.city.trim(),
        [SellerAttributes.district.componentName]: SellerDataModelKeys.district.trim(),
        [SellerAttributes.state.componentName]: SellerDataModelKeys.state.trim(),
        [SellerAttributes.country.componentName]: SellerDataModelKeys.country.trim(),
        [SellerAttributes.managerName.componentName]: SellerDataModelKeys.managerName.trim(),
        [SellerAttributes.managerPhoneNumber.componentName]: SellerDataModelKeys.pointOfContact,
        [SellerAttributes.email.componentName]: SellerDataModelKeys.email.trim(),
        [SellerAttributes.phoneNumber.componentName]: SellerDataModelKeys.phoneNumber,
        [SellerAttributes.pinCode.componentName]: SellerDataModelKeys.pinCode
      })

}


function inputModelToStoreViewModel(storeActiveViewModel: IStoreActiveViewModel, values: any): IStoreActiveViewModel {
  
    return mergeObjects(storeActiveViewModel, values, {
        [StoreAttributes.name.componentName]: StoreDataModelKeys.storeName.trim(),
        [StoreAttributes.email.componentName]: StoreDataModelKeys.email.trim(),
        [StoreAttributes.website.componentName]: StoreDataModelKeys.websiteUrl.trim(),
        [StoreAttributes.phoneNumber.componentName]: StoreDataModelKeys.contactNumber,
        [StoreAttributes.catagories.componentName]: StoreDataModelKeys.category,
        [StoreAttributes.managerName.componentName]: StoreDataModelKeys.storeManagerName.trim(),
        [StoreAttributes.managerPhoneNumber.componentName]: StoreDataModelKeys.secondaryPhoneNumber,
        [StoreAttributes.address.componentName]: StoreDataModelKeys.storeAddress.trim(),
        [StoreAttributes.geoCode.componentName]: StoreDataModelKeys.geoCode,
        [StoreAttributes.city.componentName]: StoreDataModelKeys.city.trim(),
        [StoreAttributes.district.componentName]: StoreDataModelKeys.district.trim(),
        [StoreAttributes.pinCode.componentName]: StoreDataModelKeys.pinCode,
        [StoreAttributes.state.componentName]: StoreDataModelKeys.state.trim(),
        [StoreAttributes.country.componentName]: StoreDataModelKeys.country.trim(),
        [StoreAttributes.gstNumber.componentName]: StoreDataModelKeys.gstNumber
      })

  }

function inputModelToUserViewModel(userActiveViewModel: IUserActiveViewModel, values: any): IUserActiveViewModel {

    return mergeObjects(userActiveViewModel, values, {
       [UserAttributes.name.componentName]: UserDataModelKeys.name.trim() ,
       [UserAttributes.email.componentName]: UserDataModelKeys.email.trim(),
       [UserAttributes.phoneNumber.componentName]: UserDataModelKeys.phoneNumber,
       [UserAttributes.permissions.componentName]: UserDataModelKeys.permission,
       [UserAttributes.address.componentName]: UserDataModelKeys.address.trim(),
       [UserAttributes.pinCode.componentName]: UserDataModelKeys.pinCode,
       [UserAttributes.city.componentName]: UserDataModelKeys.city.trim(),
       [UserAttributes.district.componentName]: UserDataModelKeys.district.trim(),
       [UserAttributes.state.componentName]: UserDataModelKeys.state.trim(),
       [UserAttributes.country.componentName]: UserDataModelKeys.country.trim()
     })

}


export {
    inputModelToSellerViewModel,
    inputModelToStoreViewModel,
    inputModelToUserViewModel
}