<template>

  <div ref="chartRef" style="width: 100%; height: 600px;"></div>

</template>

<script setup>
import {defineProps, onMounted, ref, watch} from 'vue';
import * as echarts from 'echarts';

const chartRef = ref(null);
const myChart = ref()
const props = defineProps({
  gaugevalue: {
    type: Object,
    required: false,
  },
  option: {
    type: Object,
    required: true
  }
})
onMounted(() => {
  if (chartRef.value) {
    myChart.value = echarts.init(chartRef.value);
    myChart.value.setOption(props.option);
  }
});
watch(
    () => props.option,
    (newValue) => {
      if (myChart.value) {
        myChart.value.setOption(
          newValue
        );
      }
    },
    {deep: true, immediate: true}
);

</script>

<style scoped>
/* Add any component-specific styles here */
</style>
