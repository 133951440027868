const  backgroundCustomStyle={

    backgroundImage: `url('https://media.istockphoto.com/vectors/electronics-and-devices-promotional-sale-banner-vector-id1284942187?k=20&m=1284942187&s=612x612&w=0&h=TqOq-CIj_Mjpy8QudhhRLSSNvmA5yahmnr4xngqAxps=')`,
    backgroundRepeat: "no-repeat",
    backgroundAttachment:"scroll",
    backgroundPosition: "center",
    backgroundSize: "cover",
    width: "100%",
    height: "400px"
    
  
}

const backgroundcolorStyle={
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "scroll",
    backgroundPosition: "center",
    backgroundSize: "cover",
    width: "100%",
    height: "400px",
    backgroundImage: "",
}

const backgroundrgbStyle={
    backgroundColor: "rgb(137, 166, 181)",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "scroll",
    backgroundPosition: "center",
    backgroundSize: "cover",
    width: "100%",
    height: "400px",
}
export default{backgroundCustomStyle,backgroundcolorStyle,backgroundrgbStyle}