import QRious from 'qrious'

export function getQRCode(message: string) {
    return  new QRious({
        background: 'white',
        backgroundAlpha: 0.8,
        foreground: 'black',
        foregroundAlpha: 0.8,
        level: 'L',
        padding: 25,
        size: 500,
        value: message
      });
}

export function getQRCodeImage(message: string) {
    return getQRCode(message).toDataURL('image/jpeg')
}