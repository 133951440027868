import moment from "moment";
import { reportBaseType, reportFilterType, getReportAganistReportId, dateTimeLiterals } from "@/config/dataConfig"

function settingParams(filterType, timePeriod, filterBy, storesId, reportId) {

  let params: any = {}

  switch (reportId) {
    case getReportAganistReportId.currentOrderCount:
      params = {
        startDate: timePeriod.StartDate,
        endDate: (new Date()).toISOString(),
        status: timePeriod.status,
        based: filterType.toLowerCase(),
        stores: storesId,
        filterBy: filterBy
      };

      break;
    case getReportAganistReportId.totalOrder:
      params = {
        startDate: timePeriod.StartDate,
        endDate: timePeriod.EndDate,
        status: timePeriod.status,
        based: filterType.toLowerCase(),
        stores: storesId,
        filterBy: filterBy
      };
      break;

  }

  return params
}

function daysInMonth(month, year) {
  if (month < 1 || month > 12 || year < 0) {
    return null;
  }
  return new Date(year, month, 0).getDate();
}

function getstatusAganistWeek(filterDate) {
  if (!(new Date(filterDate) instanceof Date)) {
    throw new Error('Invalid filter date');
  }

  const filterWeekDate: any = new Date(filterDate);
  const startDate: any = new Date(filterWeekDate.getFullYear(), 0, 1);
  const filterdays = Math.floor((filterWeekDate - startDate) /
    dateTimeLiterals.TimeCalu);
  const weekNumber = Math.ceil(filterdays / 7) + 1;
  const currentDate: any = new Date();
  const currentStartDate: any = new Date(currentDate.getFullYear(), 0, 1);
  const currentfilterdays = Math.floor((currentDate - currentStartDate) /
    dateTimeLiterals.TimeCalu);
  const currentweekNumber = Math.ceil(currentfilterdays / 7);
  if (!(startDate instanceof Date && !isNaN((filterWeekDate.getFullYear(), 0, 1)) &&
    currentDate instanceof Date)) {
    throw new Error('Error calculating week status');
  }
  return currentweekNumber === weekNumber ? reportBaseType.current : currentweekNumber > weekNumber ? reportBaseType.previous : reportBaseType.next
}

function getStatusAganistYearAndMonth(today, filterDate, type) {
  const getValue = (key, type) =>
    type === reportFilterType.Month
      ? key.getMonth()
      : key.getFullYear();
  const toDayValue = getValue(new Date(today), type);
  const filterDateValue = getValue(new Date(filterDate), type);
  return toDayValue === filterDateValue
    ? reportBaseType.current
    : toDayValue > filterDateValue
      ? reportBaseType.previous
      : reportBaseType.next
}

function getPercentile(value, sumTotal) {
  if(value && sumTotal) {
    return (value / sumTotal) * 100
  } else {
    return 0
  }

  return value && sumTotal ? value : 0
}

function getEndDateOfWeek(filterDate) {
  const filteringWeekDate = moment(filterDate).add(7, "days");
  const weekdate = moment(filteringWeekDate).toISOString()
  return weekdate
}

function getStartDateOfWeek(filterDate) {
  const filteringWeekDate = moment(filterDate).add(1, "days");
  return moment(filteringWeekDate).toISOString();
}

function getEndDateOfMonth(filterDate) {
  const year = new Date(filterDate).getFullYear();
  const month = new Date(filterDate).getMonth()
  const endDate = new Date(
    year,
    month,
    daysInMonth(month + 1, 0)
  );
  const filterdDay = moment(endDate).add(1, "days");
  return moment(filterdDay).toISOString();
}

function getStartDateOfMonth(filterDate) {
  const year = new Date(filterDate).getFullYear();
  const month = new Date(filterDate).getMonth()
  const filterdDate = new Date(year, month, 1);
  const filterdDay = moment(filterdDate).add(1, "days");
  return moment(filterdDay).toISOString();
}

function getEndDateOfYear(filterDate) {
  const year = new Date(filterDate).getFullYear();
  const endDate = new Date(year, 11, 31);
  const filterdDay = moment(endDate).add(1, "days");
  return moment(filterdDay).toISOString();
}

function getStartDateOfYear(filterDate) {
  const year = new Date(filterDate).getFullYear();
  const filterdDate = new Date(year, 0, 1);
  const filterdDay = moment(filterdDate).add(1, "days");
  return moment(filterdDay).toISOString();
}

function getIsoFormatTimeZone(filterdDay) {
  return moment(filterdDay).toISOString();
}

function setWeekNumbers(filterQuery) {
  let startWeekNumber;
  let endWeekNumber;
  let weekNumber = [];
  const filterDateFormat = moment(filterQuery).format("MM-DD-YYYY");
  const currentWeekNumber = moment(filterDateFormat, "MM-DD-YYYY").week();
  const year = new Date(filterQuery).getFullYear();
  if (currentWeekNumber === 0) {
    startWeekNumber = currentWeekNumber;
    endWeekNumber = currentWeekNumber + 4;
    weekNumber = range(startWeekNumber, endWeekNumber, 1);
  } else if (currentWeekNumber === moment(`12-12-${year}`, "MM-DD-YYYY").week()) {
    startWeekNumber = currentWeekNumber - 4;
    endWeekNumber = currentWeekNumber;
    weekNumber = range(startWeekNumber, endWeekNumber, 1);
  } else {
    const date = [];
    startWeekNumber = moment(filterQuery).clone().startOf("week");
    endWeekNumber = moment(filterQuery).clone().endOf("week");
    for (let m = moment(startWeekNumber); m.isBefore(endWeekNumber); m.add(1, "days")) {
      date.push(m.format("DD"));
    }
    weekNumber = date;
  }
  weekNumber = weekNumber.filter((x) => x > -1);
  return weekNumber;
}
function setMonthNumbers(filterQuery) {
  let startMonthNumber;
  let endMonthNumber;
  let monthNumber = [];
  const monthNames = [];
  const filterDateFormat = moment(filterQuery).format("MM-DD-YYYY");
  let currentMonthNumber = moment(filterDateFormat, "MM-DD-YYYY").month();
  currentMonthNumber = currentMonthNumber + 1;
  const year = new Date(filterQuery).getFullYear();
  if (currentMonthNumber === 0) {
    startMonthNumber = currentMonthNumber;
    endMonthNumber = currentMonthNumber + 5;
    monthNumber = range(startMonthNumber, endMonthNumber, 1);
  } else if (currentMonthNumber === moment(`12-12-${year}`, "MM-DD-YYYY").month()) {
    startMonthNumber = currentMonthNumber - 4;
    endMonthNumber = currentMonthNumber;
    monthNumber = range(startMonthNumber, endMonthNumber, 1);
  } else {
    startMonthNumber = currentMonthNumber - 2;
    endMonthNumber = currentMonthNumber + 2;
    monthNumber = range(startMonthNumber, endMonthNumber, 1);
  }
  monthNumber = monthNumber.filter((x) => x > 0);
  for (let i = 0; i < monthNumber.length; i++) {
    monthNames.push(moment(monthNumber[i], "MM").format("MMMM"));
  }
  return monthNames;
}
function setYearNumbers(filterQuery) {
  let startYearNumber;
  let endYearNumber;
  let yearNumber = [];
  const filterDateFormat = moment(filterQuery).format("MM-DD-YYYY");
  let currentYearNumber = moment(filterDateFormat, "MM-DD-YYYY").year();
  currentYearNumber = currentYearNumber + 1;
  const year = new Date(filterQuery).getFullYear();
  if (currentYearNumber === 0) {
    startYearNumber = currentYearNumber;
    endYearNumber = currentYearNumber + 5;
    yearNumber = range(startYearNumber, endYearNumber, 1);
  } else if (currentYearNumber === moment(`12-12-${year}`, "MM-DD-YYYY").year()) {
    startYearNumber = currentYearNumber - 4;
    endYearNumber = currentYearNumber;
    yearNumber = range(startYearNumber, endYearNumber, 1);
  } else {
    startYearNumber = currentYearNumber - 2;
    endYearNumber = currentYearNumber + 2;
    yearNumber = range(startYearNumber, endYearNumber, 1);
  }
  yearNumber = yearNumber.filter((x) => x > 0);
  return yearNumber;
}
function range(start, end, step) {
 if (step <= 0) {
    return[]
  }

  const len = Math.floor((end - start) / step) + 1;
  return Array(len)
    .fill(undefined)
    .map((_, idx) => start + idx * step);
}
function xLabelValue(filterType, getTimePeriods, filterQuery, item, salesValue) {
  if (filterType == reportFilterType.Week) {
    const date = getTimePeriods;
    const filterEndDateFormat = moment(date?.EndDate).format("DD");
    return parseInt(filterEndDateFormat) >= parseInt(item) ? salesValue : 0;
  } else if (filterType == reportFilterType.Month) {
    const filterDateFormat = moment(filterQuery).format("MM-DD-YYYY");
    const currentMonthNumber = moment(filterDateFormat, "MM-DD-YYYY").month();
    const currentMonthName = moment(currentMonthNumber + 1, "MM").format("MMMM");
    return currentMonthName === item ? salesValue : 0;
  } else {
    const filterDateFormat = moment(filterQuery).format("MM-DD-YYYY");
    const currentYearNumber = moment(filterDateFormat, "MM-DD-YYYY").year();
    console.log(currentYearNumber,item,"curr")
    return currentYearNumber === item ? salesValue : 0;
  }
}
function setOrderArrayValue(reportCurrentOrderViewModel) {
  const arrayRange = (start, stop, step) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (value, index) => start + index * step
    );

  const orderCount = reportCurrentOrderViewModel.orderCount;
  const endingPoint = orderCount + 3;
  const startingPoint = orderCount <= 1 ? 0 : orderCount - 2;
  const orderArrayValue = arrayRange(startingPoint, endingPoint, 1);
  return orderArrayValue;
}
export { range,daysInMonth, setOrderArrayValue, xLabelValue, setWeekNumbers, setMonthNumbers, setYearNumbers, settingParams, getstatusAganistWeek, getStatusAganistYearAndMonth, getPercentile, getEndDateOfWeek, getStartDateOfWeek, getIsoFormatTimeZone, getStartDateOfYear, getEndDateOfYear, getStartDateOfMonth, getEndDateOfMonth, }