
import { IGroupDataModel } from "@/Interfaces/DataModel/ChatDataModel";
import { IResponseDataModel } from "@/Interfaces/DataModel/ResponseDataModel";
import { chatApi } from "../Common/ApiRoutes";
import { ApiService as ApiServiceClass } from "../Common/ApiService";
import { config } from "@/config/apiConfig"

const ApiService = new ApiServiceClass(config.signalR.baseUrl)

function getGroups(sellerId: string, regionId: string): Promise<IResponseDataModel<IGroupDataModel[]>>  {
    const { url, method }: { url: string, method: string } = chatApi.getGroups
    return ApiService.SendRequest(url, method, { query: { sellerId, regionId } })
}

function getUsersInGroup(groupId: string, regionId: string):  Promise<IResponseDataModel<IGroupDataModel[]>> {
    const { url, method }: { url: string, method: string } = chatApi.getUsersInGroup(groupId)
    return ApiService.SendRequest(url, method, { query: { regionId } })
}

function getChatHistory(data: any):  Promise<IResponseDataModel<IGroupDataModel[]>> {
    const { url, method }: { url: string, method: string } = chatApi.getChatHistory
    return ApiService.SendRequest(url, method, { data, query: { skip: 0, limit: 0 } })
}

export {
    getGroups,
    getUsersInGroup,
    getChatHistory
}