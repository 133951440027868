import { integrationChannelTemplate, ViewModelStatus } from "@/config/dataConfig";
import { IIntegrationActiveViewModel, IIntergationChannelListViewModel, IntegrationComponent, Integrations } from "@/Interfaces/DataModel/IntegrationDataModel";
import axios from "axios";

export async function loadIntegrationTemplateFromGitHb() {
    try {
        const response = await axios.get(integrationChannelTemplate);
        const jsonData = typeof response.data === 'string' ? JSON.parse(response.data) : response.data;
        return jsonData
    } catch (error) {
        console.error('Error fetching data:', error);
    }

}


export function convertTemplateDataModelToViewModel(dataModel: any): IIntergationChannelListViewModel[] {
    const result = transformData(dataModel)
    return result

}
function transformData(data: Integrations): IIntergationChannelListViewModel[] {
    return Object.entries(data).map(([key, value]) => {
        const {
            addedAtUtc,
            correlationId,
            lastModifiedBy,
            sellerId,
            parameters,
            userId,
            storeId,
            lastModifiedOn,
            version,
            regionId,
            icon,
            tag,
            integrationName,
            componentId,
            componentName,
            source,
            ...rest
        } = value;


        const emptyValues = Object.fromEntries(
            Object.keys(rest).map(field => [field, ''])
        );
        const emptyValuesForParemeters = Object.fromEntries(
            Object.keys(parameters).map(field => [field, ''])
        );
        return {
            type: key ==='whatsapp'?'WhatsappCommunication':key ,
            integrationName: key ==='whatsapp'?'WhatsappCommunication':key,
            componentId: "",
            componentName: "",
            source: "",
            icon: value.icon,
            tag: value.tag,
            parameters: emptyValuesForParemeters,
            ...emptyValues,
        };
    });
}



export function convertTemplateToViewModel(viewModel:IIntergationChannelListViewModel, TemplateViewModel:IIntergationChannelListViewModel[], listViewModel:IIntegrationActiveViewModel[]) {
    let modifiedChannel = {} as any
    const unifiedWhatsapp = createUnifiedDataModel(viewModel, TemplateViewModel);
    unifiedWhatsapp.componentName = `${unifiedWhatsapp.integrationName} Integration`
    const existingItem = listViewModel.find(item => item.integrationName === unifiedWhatsapp.integrationName);
    if (existingItem) {

        modifiedChannel = { ...unifiedWhatsapp }
        modifiedChannel.default = false
    } else {
        modifiedChannel = { ...unifiedWhatsapp }
    }
    return modifiedChannel
}


function createUnifiedDataModel(
    additionalData: any,
    components: IIntergationChannelListViewModel[]
): IIntegrationActiveViewModel {
    let component = null;
    components.some(c => {
        if (c.type === additionalData.integrationName) {
            component = c;
            return true;
        }
        return false;
    });
    if (!component) {
        const metdata = {
            wcVersion: "",
            destination: "",
            customerKey: "",
            customerSecretKey: "",
            email: "",

        }
        return {
            ...additionalData,
            parameters: metdata,
            IsSaved: ViewModelStatus.New


        }

    } else {
        return {
            ...component,
            ...additionalData,
            IsSaved: ViewModelStatus.New
        };
    }
}

export function convertToIntergationChannelDataModel(integrationChannel: IIntergationChannelListViewModel, viewModel: IIntergationChannelListViewModel) {

    viewModel.integrationName = integrationChannel.integrationName,
        viewModel.componentName = integrationChannel.componentName,
        viewModel.componentId = integrationChannel.componentId,
        viewModel.parameters = { ...integrationChannel.parameters }
    return viewModel

}


export function modelValueEmtyOrNot(integrationChannelActiveViewModel:IIntegrationActiveViewModel) {
    const { componentName, source, integrationName, parameters } = integrationChannelActiveViewModel;
    if (componentName.trim() === '' || source.trim() === '' || integrationName.trim() === '') {
        return false;
    }
    for (const key in parameters) {
        if (Object.prototype.hasOwnProperty.call(parameters, key)) {
          if (!parameters[key].trim()) {
            return false;
          }
        }
      }

    return true;
}