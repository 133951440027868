<template>
  <div ref="chartContainer" style="width: 100%; height: 400px"></div>
</template>

<script setup>
import { onMounted, ref, defineProps,watch } from "vue";
import * as echarts from "echarts";

const myChart = ref(null);
const chartContainer = ref(null);
const props = defineProps({
  shareDatasetConfig: {
    type: Object,
    required: true,
  },
});
onMounted(() => {
  myChart.value = echarts.init(chartContainer.value);
  if (props.shareDatasetConfig) {
    myChart.value.setOption(props.shareDatasetConfig);
  }
});
watch(
  () => props.shareDatasetConfig,
  (newConfig) => {
    if (myChart.value) {
      myChart.value.setOption(newConfig, true);
    }
  },
  { deep: true }
);
</script>

<style scoped></style>
