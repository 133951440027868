import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PolarArea = _resolveComponent("PolarArea")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_PolarArea, {
      "chart-options": _ctx.chartOptions,
      "chart-data": $props.chartData,
      "chart-id": _ctx.chartId,
      "dataset-id-key": _ctx.datasetIdKey,
      plugins: _ctx.plugins,
      "css-classes": _ctx.cssClasses,
      styles: _ctx.styles,
      width: _ctx.width,
      height: _ctx.height
    }, null, 8, ["chart-options", "chart-data", "chart-id", "dataset-id-key", "plugins", "css-classes", "styles", "width", "height"])
  ]))
}