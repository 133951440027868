import { ISellerDataModel } from "@/Interfaces/DataModel/SellerDataModel";
import { IResponseDataModel } from "@/Interfaces/DataModel/ResponseDataModel"
import { SellerApi } from "../Common/ApiRoutes";
import ApiService from "../Common/ApiService";


async function GetASeller(sellerId: string): Promise<IResponseDataModel<ISellerDataModel>> {
  const { url, method }: { url: string, method: string } = SellerApi.GetASellerUrl(sellerId);
 return  await ApiService.SendRequest(url, method)
}

async function UpdateASeller(sellerId: string, data: ISellerDataModel): Promise<IResponseDataModel<ISellerDataModel>> {

    const { url, method }: { url: string, method: string } = SellerApi.UpdateASellerUrl(sellerId);
    return await ApiService.SendRequest(url, method, { data })
  
  }

export { GetASeller, UpdateASeller }