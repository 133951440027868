enum SellerDataModelKeys {
  address        = "address",
  city           = "city",
  country        = "country",
  district       = "district",
  email          = "email",
  files          = "files",
  logo           = "logo",
  orgType        = "orgType",
  phoneNumber    = "phoneNumber",
  pinCode        = "pinCode",
  sellerName     = "sellerName",
  state          = "state",
  status         = "status",
  website        = "website",
  managerName    = "managerName",
  pointOfContact = "pointOfContact",
  sellerId       = "sellerId",
  storeId        = "storeId",
  userId         = "userId",
  regionId       = "regionId",
  isLocked       = "isLocked"
}

interface ISellerDataModel {
  [SellerDataModelKeys.address]: string;
  [SellerDataModelKeys.city]: string;
  [SellerDataModelKeys.country]: string;
  [SellerDataModelKeys.district]: string;
  [SellerDataModelKeys.email]: string;
  [SellerDataModelKeys.files]: string[];
  [SellerDataModelKeys.logo]: string;
  [SellerDataModelKeys.orgType]: string;
  [SellerDataModelKeys.phoneNumber]: string;
  [SellerDataModelKeys.pinCode]: string;
  [SellerDataModelKeys.sellerName]: string;
  [SellerDataModelKeys.state]: string;
  [SellerDataModelKeys.status]: string;
  [SellerDataModelKeys.website]: string;
  [SellerDataModelKeys.managerName]: string;
  [SellerDataModelKeys.pointOfContact]: string;
  [SellerDataModelKeys.sellerId]: string;
  [SellerDataModelKeys.storeId]: string;
  [SellerDataModelKeys.userId]: string;
  [SellerDataModelKeys.regionId]: string;
  [SellerDataModelKeys.isLocked]: string;
}

interface ISellerActiveViewModel {
  [SellerDataModelKeys.address]: string;
  [SellerDataModelKeys.city]: string;
  [SellerDataModelKeys.country]: string;
  [SellerDataModelKeys.district]: string;
  [SellerDataModelKeys.email]: string;
  [SellerDataModelKeys.files]: string[];
  [SellerDataModelKeys.logo]: string;
  [SellerDataModelKeys.orgType]: string;
  [SellerDataModelKeys.phoneNumber]: string;
  [SellerDataModelKeys.pinCode]: string;
  [SellerDataModelKeys.sellerName]: string;
  [SellerDataModelKeys.state]: string;
  [SellerDataModelKeys.status]: string;
  [SellerDataModelKeys.website]: string;
  [SellerDataModelKeys.managerName]: string;
  [SellerDataModelKeys.pointOfContact]: string;
  [SellerDataModelKeys.sellerId]: string;
  [SellerDataModelKeys.storeId]: string;
  [SellerDataModelKeys.userId]: string;
  [SellerDataModelKeys.regionId]: string;
  [SellerDataModelKeys.isLocked]: string;
}

export { ISellerDataModel, ISellerActiveViewModel, SellerDataModelKeys };
