
import ApiService from "../Common/ApiService";
import { reportApi } from "../Common/ApiRoutes";
import { IResponseDataModel } from "@/Interfaces/DataModel/ResponseDataModel";

function GetReport(sellerId: string, type: string, query?: any): Promise<IResponseDataModel<{}>> {
    const { url, method }: { url: string, method: string } = reportApi.getReports(sellerId, type)
    return ApiService.SendRequest(url, method, { query: query })


}


export {
    GetReport,


}