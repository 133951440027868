<template>
  <div class="container-fluid">
    <div class="row mb-3">
      <!-- Ensure each card takes the full width of the row -->
      <div class="col-7 mb-4" v-for="(card, index) in cards" :key="index">
        <div class="card h-100">
          <div class="card-header">
            <h6 class="mb-0 text-xs text-left" style="color: #888686">
              {{ card.title }} <span class="tag" style="margin-left: 3px">PRO ✨</span>
            </h6>
          </div>
          <div class="mt--4">
            <p class="px-3 text-bolder text-dark">{{ card.content }}</p>
            
            <img
              :src="require(`@/assets/img/graph/${card.image}`)"
              alt="Card image"
              class="card-image mt--8"
              style="margin-top: -5%"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, watch } from "vue";

// Define the prop and its type
const props = defineProps({
  responsiveRowCardConfig: {
    type: Array,
    required: true,
  },
});

// Initialize the cards ref
const cards = ref(props.responsiveRowCardConfig);

// Watch for changes in the responsiveRowCardConfig prop
watch(
  () => props.responsiveRowCardConfig,
  (newValue) => {
    cards.value = newValue;
  }
);
</script>

<style scoped>
.card {
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-body {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
}

.card-header h6 {
  margin-bottom: 0.5rem;
}

.card-content {
  margin-bottom: 1rem;
  text-align: left;
}

.card-image {
  object-fit: cover;
}

.tag {
  background-color: #144888;
  color: white;
  padding: 3px 8px;
  border-radius: 10px;
  font-size: 12px;
  font-weight: bold;
}
</style>
