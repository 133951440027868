import { ViewModelStatus as GroupStatus } from '@/config/dataConfig'
import { IStoreDataModel } from "@/Interfaces/DataModel/StoreDataModel";



function CreateSentMessageModel(message: any, SellerId: string, RegionId: string)
{
    const storeId = message.roomId.split("+")[1];
    const data = {
      sellerId: SellerId,
      regionId: RegionId,
      userId: storeId,
      groupId: message.roomId,
      messageData: message.content,
      userType: "store",
      senderId: storeId,
      storeId: storeId,
    };
    return data;
}


function CreateNewGroupModel(store: IStoreDataModel, SellerId: string, RegionId: string) {
        return { 
        regionId: RegionId,
        sellerId: SellerId,
        storeId: store.storeId,
        userId: store.storeId, // storeId + current userId
        creatorId: SellerId,
        groupId: getPublicGroupId(store.storeId, SellerId),
        groupName: store.storeName,
        userName: store.storeName,
        status: GroupStatus.New,
        groupType: 'public'
        }
}

function getPublicGroupId (storeId: string, SellerId: string,) {
    return `${SellerId}+${storeId}`
}

export{
    CreateSentMessageModel,
    CreateNewGroupModel,
    getPublicGroupId


}