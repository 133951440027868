const inputFieldConstants = {
  name: {
    id: "sellerName",
    label: "Name",
    placeHolder: "Seller Name",
    componentName: "Name",
  },
  address: {
    id: "address",
    label: "Address",
    placeHolder: "Seller Address",
    componentName: "Address",
  },
  city: {
    id: "city",
    label: "City",
    placeHolder: "City",
    componentName: "City",
  },
  district: {
    id: "district",
    label: "District",
    placeHolder: "District",
    componentName: "District",
  },
  state: {
    id: "state",
    label: "State",
    placeHolder: "State",
    componentName: "State",
  },
  pinCode: {
    id: "pincode",
    label: "Pincode",
    placeHolder: "Pincode",
    componentName: "Pincode",
  },
  managerName: {
    id: "managerName",
    label: "Manager Name",
    placeHolder: "Manager Name",
    componentName: "Manager Name",
  },
  country: {
    id: "country",
    label: "Country",
    placeHolder: "Country",
    componentName: "Country",
  },
  managerPhoneNumber: {
    id: "managerPhoneNumber",
    label: "Phone Number",
    placeHolder: "Manager Phone Number",
    componentName: "Manager Phone Number",
  },
  email: {
    id: "email",
    label: "Email",
    placeHolder: "Email",
    componentName: "Email",
  },
  phoneNumber: {
    id: "phoneNumber",
    label: "Phone Number",
    placeHolder: "Phone Number",
    componentName: "Phone Number",
  },
};

export { inputFieldConstants };
